<!-- 文件上传进度条-->
<template>
    <el-dialog
            class="fpl-progress"
            :visible="isShow"
            :close-on-click-modal="isClickModalClose"
            :close-on-press-escape="false"
            :show-close="false"
            :width="defaultWidth"
    >
        <div class="fpl-progress-content">
            <p v-if="uploadPercent === 100">头像上传已完成</p>
            <p v-else>正在上传-不可继续操作</p>
            <el-progress :text-inside="true" :stroke-width="24" :percentage="uploadPercent" :status="uploadPercent === 100 ? 'success': null" style="margin-top: 20px;"></el-progress>

            <el-button type="primary" v-if="uploadPercent === 100" @click="complete" style="margin-top: 20px;">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import modalMixin from '@/mixins/modalMixin';

    export default {
        props: ['uploadPercent'],
        mixins: [modalMixin],
        data() {
            return {};
        },
        methods: {
            complete(){
                this.$emit('complete');
                this.closeModal();
            }
        }
    }
</script>

<style>
    .fpl-progress .el-dialog__header{
        display: none;
    }
    .fpl-progress .fpl-progress-content {
        font-size: 20px;
        text-align: center;
        background: #fff;
        border-radius: 5px;
    }
</style>
