<template>
  <div class="top-container">
    <el-row type="flex" align="middle" class="header">
      <el-col :span="16">
        <div class="toggle pull-left" @click="toggleExpand">
          <i :class="toggleIcon"></i>
          <h1 class="title">{{ $store.getters.getSetting.title }}</h1>
        </div>
      </el-col>
      <el-col :span="8">
        <!-- 警告 -->
        <div class="pull-right">
          <el-dropdown
            style="
              display: inline-block;
              height: 100%;
              line-height: 50px;
              cursor: pointer;
            "
            trigger="click"
          >
            <span
              style="display: inline-block; height: 100%; line-height: 50px"
            >
              <span slot="title">
                <img class="icon-bell" src="@/assets/image/bell.png" alt="" />
              </span>
            </span>
            <template #dropdown>
              <div class="drop-warning">
                <div class="drop-title">异常警报</div>
                <div class="drop-item" v-for="(item,index) in warningList">
                  {{item.stationMacid}}--{{item.stationName}}--{{item.warninfo}}--{{item.createAt}}
                </div>
              </div>
            </template>
          </el-dropdown>

          <!-- 下拉修改密码 -->
          <el-dropdown
            style="
              display: inline-block;
              height: 100%;
              line-height: 50px;
              cursor: pointer;
            "
            trigger="click"
          >
            <span
              style="display: inline-block; height: 100%; line-height: 50px"
            >
              <span slot="title">{{userInfo.nickName}}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click.native="showUpdatePwd"
                  >修改密码</el-dropdown-item
                >
                <!-- <el-dropdown-item @click.native="goto('/user-set')">个人信息</el-dropdown-item> -->
                <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>

    <el-row type="flex" align="middle" class="screen-small">
      <el-col :span="8">
        <breadcrumb />
      </el-col>
    </el-row>

    <UpdatePwdModal
      @submit="doUpdatePwd"
      :modalData="modalData"
      :ref="MODAL_KEY.UPDATE_PWD_MODAL"
    ></UpdatePwdModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import UpdatePwdModal from "@/components/view-components/update-pwd-modal";
import { updateMyPwd } from "@/api/user";
import { fetchStationWarningList } from "@/api/station";

export default {
  name: "AppHeader",
  components: {
    Breadcrumb,
    UpdatePwdModal,
  },
  data() {
    return {
      userInfo: this.$store.getters.userInfo||{},
      modalData: {},
      isShowTips: false,
      MODAL_KEY: {
        UPDATE_PWD_MODAL: "UPDATE_PWD_MODAL",
      },
      warningList: [],
      pages: {
        pageIndex: 1,
        size: 20,
      },
      query: {
        warninfo: "",
        stationMacid: "",
        level: -1,
        doinfo: "",
        type: -1,
        adminId: -1,
        warncode: "",
        stationId: -1,
        status: -1,
      },
    };
  },
  computed: {
    ...mapState({
      userType: (state) => {
        return "";
      },
      toggleIcon: (state) => {
        return state.sidebarOpened ? "el-icon-s-fold" : "el-icon-s-unfold";
      },
    }),
    ...mapGetters(["userInfo"]),
  },
  methods: {
    showUpdatePwd() {
      this.$refs[this.MODAL_KEY.UPDATE_PWD_MODAL].showModal();
    },
    doUpdatePwd(obj) {
      updateMyPwd({
        ...obj,
      }).then((res) => {
        this.$message.success("修改成功");
        this.$refs[this.MODAL_KEY.UPDATE_PWD_MODAL].closeModal();
      });
    },
    logout() {
      this.$router.push("/login");
      this.$store.dispatch("LogOut");
    },
    goto(url) {
      this.$router.push(url);
    },
    toggleExpand() {
      this.$store.dispatch("toggleSideBar");
    },

    getWarning() {
      fetchStationWarningList({
        ...this.query,
        ...this.pages,
      }).then((res) => {
        this.warningList = res.data.data.records||[];
      });
    },
  },
  watch: {},
  mounted() {
    this.getWarning();
  },
};
</script>

<style lang="less" scoped>
.icon-bell {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: middle;
}
.drop-warning {
  width: 300px;
  height: 400px;
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  white-space: nowrap;
  .drop-title{
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    font-weight: bold;
    font-weight: 20px;
    border-bottom: 1px solid #e1e1e1;
  }
  .drop-item{
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #e1e1e1;
    padding-left: 20px;
  }
}

.top-container {
  margin: 0 -20px;

  .header {
    background-color: #fff;
    height: 50px;
    padding: 0 20px;
    overflow: hidden;
    position: relative;
    z-index: 20;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .title {
      font-size: 20px;
      color: #30a5ff;
      display: inline-block;
      margin-left: 20px;
    }
  }

  .screen-small {
    padding: 0 20px;
    height: 40px;
    background-color: #f1f1f1;
    border-top: 1px solid #d8dce5;
  }

  .toggle {
    font-size: 20px;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>
