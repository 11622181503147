import { serialize } from '@/common/util'
import errorCode from '@/const/errorCode'
import store from '@/store'
import { ElMessage, ElLoading } from 'element-plus'
import axios from 'axios'
import { baseUrl } from '@/config/env'
import { validatenull } from '../common/validate'
import vueRouter from '@/router/index'

// 超时时间
//axios.defaults.timeout = 120000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = false;

let countRequest = 0
let loadingInstance;

let isRefreshing = true;
// HTTPrequest拦截
axios.interceptors.request.use(config => {
    if (!config.unLoading) {
        countRequest++ //合并请求计数；
    }
    loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
    });
  
    if(config.unsys){
        config.url = baseUrl + config.url
    } else {
        config.url = baseUrl + config.url
    }
    

    let token = store.getters.token;
    console.log(store,"=====",token)
    if (token && validatenull(config.headers['token']) && !config.unToken) {
        config.headers['token'] = token// token
    }

    // headers中配置serialize为true开启序列化
    if (config.methods === 'post' && config.headers.serialize) {
        config.data = serialize(config.data)
        delete config.data.serialize
    }
    console.log(config,"config");
    if (config.params) { // 清空为空的参数
        Object.keys(config.params).map(item => {
            if (validatenull(config.params[item])) {
                delete config.params[item]
            }
        })
    }

    // 情况为空的参数
    if(!validatenull(config.data)) { 
        let data = config.data;
        Object.keys(data).map(item => {
            if (validatenull(data[item])) {
                delete data[item]
            }
        })
        config.data = data;
    }
    return config
}, error => {
    return Promise.reject(error)
})

var newAxios = axios.create();

function checkStatus(res) {
    let iRspResult = res.data.code;
    console.log(iRspResult,"iRspResult");
    
    // 获取到的token是空的， 并且报 401401 这是因为没有登录， 跳转到登录界面  TODO 无法跳转login 是因为界面退出是报错，contentmenuList
    if (validatenull(store.getters.token) && (iRspResult === 4001 || iRspResult === 4001)) {
        vueRouter.replace('/login');
        return res;
    }

    // token 过期
    // if (iRspResult === 4001|| iRspResult === 4001) {
    //     if (isRefreshing) {
    //         refreshTokenRequst()
    //     }
    //     isRefreshing = false;

    //     const retryOriginalRequest = new Promise((resolve) => {
    //         addSubscriber(() => {
    //             res.config.headers.token = store.getters.token
    //             resolve(newAxios({ ...res.config }))
    //         })
    //     });
    //     return retryOriginalRequest;
        
    // } else {
    //     return res;
    // }
    return res;
}

// HTTPresponse拦截
axios.interceptors.response.use(res => {
    return new Promise((resolve, reject) => {
            resolve(checkStatus(res));
    }).then(res => {
        if (!res.config.unLoading) {
            countRequest-- //合并请求计数；
        }
        if (countRequest === 0) {
            setTimeout(() => {
                loadingInstance.close()
            }, 500)
        }
        
        if (res.config.responseType === "arraybuffer") {
            return res
        }
        //LoadingBar.finish();
        const status = res.data.code;
        const message = res.data.message || '服务异常' + status;

        if ((status !== 200 && status !== "200") || res.status !== 200) {
            ElMessage({
                message: message,
                type: 'error'
            });
            return Promise.reject(new Error(message))
        }

        return res
    })
}, error => {
    console.log('==========error',error);
    checkStatus(error)
    countRequest--
    if (countRequest === 0) {
        setTimeout(() => {
            loadingInstance.close()
        }, 500)
    }
    
    ElMessage({
        message: '服务异常',
        type: 'error'
    });
    return Promise.reject(new Error(error))
})

// 刷新token
function refreshTokenRequst() {
    store.dispatch("handleRefreshToken").then(res => {
        // 保存，更新token
        onAccessTokenFetched();
        isRefreshing = true;
    }).catch(res => {
        console.log('err', res)
    })
}

let subscribers = [];
function onAccessTokenFetched() {
    subscribers.forEach((callback) => {
        callback();
    })
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback)
}

export default axios
