/*
* 路由配置
* */
import { createRouter, createWebHistory } from 'vue-router'
import { menuList, IGNORE, globalList } from './nav';
import store from '@/store';

const _import = require('./import-' + process.env.NODE_ENV)

const globalRoutes = {
    path: '/global', name: 'global', component: require('@/layout/web/Index').default, children: [],
}

const mainRoutes = {
    path: '/home',
    name: 'home',
    component: require('@/layout/admin/Index').default,
    children: [],
    meta: '首页'
};

getGlobalRoutes(globalList);
getMainRoutes(menuList);

const vueRouter = createRouter({
    history: createWebHistory(),
    mode: 'hash',
    scrollBehavior: () => {
        y: 0
    },
    isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
    base: __dirname,
    routes: [globalRoutes, mainRoutes,
        { path: '/statistics', name: 'global-statistics', component: require('@/views/global/statistics').default, children: [] },
        { path: '/', redirect: { name: 'login' } }]
})

//获取 全局 路由
function getGlobalRoutes(menuList = [], permission = {}) {
    let routes = parseRouter(menuList, permission = {});
    globalRoutes.name = "dynamicRouter1";
    globalRoutes.children = routes;
}

//获取admin路由deepClone
function getMainRoutes(menuList = []) {
    let routes = parseRouter(menuList)
    mainRoutes.name = "dynamicRouter";
    mainRoutes.children = routes;
    console.log(vueRouter, 'vueRouter')
}

function parseRouter(menuList = []) {
    let routes = [];
    menuList.map(item => {
        item.children && item.children.map(child => {
            // 不在这里做 router 权限校验， 直接在 before 里面校验权限
            if (child.component) {
                routes.push({
                    name: child.name,
                    meta: {
                        title: child.title,
                        permission: child.permission,
                        keepAlive: child.meta ? child.meta.keepAlive : false,
                    },
                    component: _import(child.component),
                    path: child.path
                })
            }
        })
    })
    return routes;
}

vueRouter.beforeEach((to, from, next) => {
    vueRouter.options.isAddDynamicMenuRoutes = true;
    if (vueRouter.options.isAddDynamicMenuRoutes || fnCurrentRouteType(to, globalRoutes.children) === 'global') {
        next()
    } else {
        if (!store.getters.permissions[to.meta.permission] && to.meta.permission !== IGNORE) { //没有token
            next({ path: '/login' })
        } else {
            vueRouter.options.isAddDynamicMenuRoutes = true;
            next({ ...to, replace: true })
        }
    }
})

vueRouter.afterEach(to => {
    store.dispatch('page/open', to)
})


function fnCurrentRouteType(route, globalRoutes = []) {
    let temp = []
    for (var i = 0; i < globalRoutes.length; i++) {
        if (route.path === globalRoutes[i].path) {
            return 'global'
        } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
            temp = temp.concat(globalRoutes[i].children)
        }
    }
    return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}


export default vueRouter;
