//外部的页面

export default function (IGNORE) {
    return {
        title: 'global',
        name: 'global',
        children: [{
            title: '登录',
            path: '/login',
            name: 'login',
            component: 'global/login',
            permission: IGNORE
        }]
    }
}