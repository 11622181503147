<template>
  <div class="d2-multiple-page-control-group" flex>
    <div class="d2-multiple-page-control-content" flex-box="1">
      <div class="d2-multiple-page-control-content-inner">
        <d2-contextmenu
          :visible.sync="contextmenuFlag"
          :x="contentmenuX"
          :y="contentmenuY"
        >
          <d2-contextmenu-list
            :menulist="tagName === '/' ? contextmenuListIndex : contextmenuList"
            @rowClick="contextmenuClick"
          />
        </d2-contextmenu>
        <el-tabs
          v-model="current"
          type="card"
          :closable="true"
          @tab-click="handleClick"
          @edit="handleTabsEdit"
          @contextmenu.native="handleContextmenu"
        >
          <el-tab-pane
            v-for="page in opened"
            :key="page.fullPath"
            :label="page.title || '未命名'"
            :name="page.fullPath"
          />
        </el-tabs>
      </div>
    </div>
    <div flex-box="0" class="tab-left-menu">
      <el-dropdown
        trigger="click"
        size="default"
        split-button
        @click="closeAll"
        @command="(command) => handleControlItemClick(command)"
      >
        关闭全部<i class="font_family icon-cha"/>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="left">
              <i class="el-icon-back tab-icon"></i>
              关闭左侧
            </el-dropdown-item>
            <el-dropdown-item command="right">
              <i class="el-icon-right tab-icon" />
              关闭右侧
            </el-dropdown-item>
            <el-dropdown-item command="other">
              <i class="el-icon-close tab-icon" />
              关闭其它
            </el-dropdown-item>
            <el-dropdown-item command="all">
              <i class="font_family icon-cha" />
              全部关闭
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import D2Contextmenu from "../contextmenu";
import D2ContextmenuList from "../contextmenu/components/contentmenuList";

export default {
  components: {D2ContextmenuList, D2Contextmenu},
  data() {
    return {
      contextmenuFlag: false,
      contentmenuX: 0,
      contentmenuY: 0,
      contextmenuListIndex: [
        { icon: "times-circle", title: "关闭全部", value: "all" },
      ],
      contextmenuList: [
        { icon: "el-icon-back", title: "关闭左侧", value: "left" },
        { icon: "el-icon-right", title: "关闭右侧", value: "right" },
        { icon: "el-icon-close", title: "关闭其它", value: "other" },
        { icon: "font_family icon-cha", title: "关闭全部", value: "all" },
      ],
      tagName: "/",
    };
  },
  computed: {
    ...mapState("page", ["opened", "current"]),
  },
  methods: {
    ...mapActions("page", [
      "close",
      "closeLeft",
      "closeRight",
      "closeOther",
      "closeAll",
    ]),
    /**
     * @description 右键菜单功能点击
     */
    handleContextmenu(event) {
      let target = event.target;
      // 解决 https://github.com/d2-projects/d2-admin/issues/54
      let flag = false;
      if (target.className.indexOf("el-tabs__item") > -1) flag = true;
      else if (target.parentNode.className.indexOf("el-tabs__item") > -1) {
        target = target.parentNode;
        flag = true;
      }
      if (flag) {
        event.preventDefault();
        event.stopPropagation();
        this.contentmenuX = event.clientX;
        this.contentmenuY = event.clientY;
        this.tagName = target.getAttribute("aria-controls").slice(5);
        this.contextmenuFlag = true;
      }
    },
    /**
     * @description 右键菜单的row-click事件
     */
    contextmenuClick(command) {
      this.handleControlItemClick(command, this.tagName);
    },
    /**
     * @description 接收点击关闭控制上选项的事件
     */
    handleControlItemClick(command, tagName = null) {
      if (tagName) {
        this.contextmenuFlag = false;
      }
      const params = {
        pageSelect: tagName,
      };
      switch (command) {
        case "left":
          this.closeLeft(params);
          break;
        case "right":
          this.closeRight(params);
          break;
        case "other":
          this.closeOther(params);
          break;
        case "all":
          this.closeAll();
          break;
        default:
          this.$message({
            message: "无效的操作",
            type: "error",
          });
          break;
      }
    },
    /**
     * @description 接收点击 tab 标签的事件
     */
    handleClick(tab, event) {
        // 找到点击的页面在 tag 列表里是哪个
      const page = this.opened.find((page) => {
        return page.fullPath === tab.props.name;
      });
      const { params, query, path } = page;
      if (page) {
        this.$router.push({ path, params, query });
      }
    },
    /**
     * @description 点击 tab 上的删除按钮触发这里 首页的删除按钮已经隐藏 因此这里不用判断是 index
     */
    handleTabsEdit(tagName, action) {

      if (action === "remove") {
        this.close({
          tagName,
        });
      }
    },
  },
};
//#f5f7f9
</script>

<style lang="less">
.d2-multiple-page-control-group {
  display: flex;
}

.d2-multiple-page-control-content {
  flex: 1;
  width: 1px;
}

.d2-multiple-page-control-group
  .el-tabs__nav
  .el-tabs__item:first-child
  .el-icon-close {
  display: none;
}

.tab-icon {
  font-weight: bold !important;
}

.tab-left-menu {
  display: flex;
  align-items: flex-end;

  .font_family {
    font-size: 14px;
  }
}

.d2-multiple-page-control-group .el-tabs__nav .el-tabs__item.is-active {
  background-color: #fff;
  border-bottom-color: #fff;
}

[flex-box="0"] {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[flex-box="1"] {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
</style>
