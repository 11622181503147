import request from '@/common/axios';
import store from '@/store';
// 查询订单列表
export function fetchOrderList(data) {

  return request({
    url: '/web/admin/order/adminOrderPage',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 订单详情
export function fetchOrderDetail(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 604,
      oReqData: {
        ...data
      }
    }
  });
}

// 开门记录
export function fetchOpenDoorList(data) {

  return request({
    url: '/web/admin/station/cabinet/stationCabinetLogPage',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 订单退款
export function orderRefund(data) {
  return request({
    url: '/web/admin/order/adminOrderRefund',
    method: 'POST',
    data: data,
  })
}

// 退款订单导出 
export function exportOrderRefund(data) {
  return request({
    url: '/web/admin/refund/export',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: data,
  });
}

// 订单退款列表
export function orderRefundList(data) {
  return request({
    url: '/web/admin/refund/refundPage',
    method: 'POST',
    data: data,
  })
}

// 导出订单数据
export function exportOrderList(data) {
  return request({
    url: '/web/admin/order/orderExcelDownload',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: data,
  });
}

// 换电记录导出  /v1/excel/userUse/down
export function exportExchangeList(data) {
  return request({
    url: '/excel/deviceUser/down',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: {
      "iReqCode": 0,
      "oReqData": data,
    }
  });
}

// 开门记录导出   /v1/excel/doorOpen/down
export function exportOpenDoorList(data) {
  return request({
    url: '/excel/doorOpen/down',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: {
      "iReqCode": 0,
      "oReqData": data,
    }
  });
}


// 用户换电日志
export function fetchUserEventList(data) {
  return request({
    url: '/web/admin/user/event/userEventPage',
    method: 'POST',
    data: data
  });
}

// 订单统计
export function fetchOrderStatistics(data) {
  return request({
    url: '/web/admin/order/day/sum/month',
    method: 'POST',
    data: data
  });
}

// 押金扣款记录
export function depositSubtractRecord(data) {
  return request({
    url: '/web/admin/zfbFreezePayOrder/freezePayOrderPage',
    method: 'post',
    data: data
  })
}

// 押金手动扣除
export function depositSubtract(data) {
  return request({
    url: '/web/admin/order/zfbFreezePay',
    method: 'post',
    data: data
  })
}


// 预支付扣除关闭
export function depositSubtractClose(data) {
  return request({
    url: '/web/admin/order/orderClose',
    method: 'post',
    data: data
  })
}