import { per } from '@/const/permission';
export default function (IGNORE) {
    return {
        title: '设备数据',
        name: 'cabinet',
        icon: 'icon-cabinet',
        children: [
        {
            title: '异常警报',
            path: '/cabinet/warning',
            name: 'cabinet-warning',
            component: 'cabinet/warning',
            permission: per.SHOW_CABINET_WARNING,
            isMenu: true
        },{
            title: '仓门禁用列表',
            path: '/cabinet/ban/list',
            name: 'cabinet-ban-list',
            component: 'cabinet/ban-list',
            permission: per.SHOW_CABINET_BAN,
            isMenu: true
        },{
            title: '点位管理',
            path: '/point/list',
            name: 'point-list',
            component: 'station/point',
            permission: per.SHOW_STATION_LIST,
            isMenu: true,
        },{
            title: '点位账户管理',
            path: '/provider/account',
            name: 'provider-account',
            component: 'station/provider',
            permission: per.SHOW_STATION_LIST,
            isMenu: true,
        }]
    }
}
