import MyBox from '@/components/myBox'
import MyBadge from '@/components/myBadge'
import UploadExcel from '@/components/myUpload/uploadExcel'
import UploadBatImgModal from '@/components/myUpload/uploadBatImgModal'
import UploadImg from '@/components/myUpload/uploadImg'
import uploadFile from '@/components/myUpload/uploadFile'
import VerifyCode from '@/components/verifyCode/index'
import InputNumber from '@/components/eb-input/inputNumber'
import DetailHeader from '@/components/detail-header';
import FilterUserInput from '@/components/filter-user-list';
import DateRange from '@/components/date-range';
import OperationComponent from '@/components/operation-components';

const myCompnents = {
    install(vue, option) {
        vue.component('MyBox', MyBox)
        vue.component('icon', () => import('./d2-icon'))
        vue.component("eb-badge", MyBadge)
        vue.component("UploadExcel", UploadExcel)
        vue.component("UploadBatImgModal", UploadBatImgModal)
        vue.component("UploadImg", UploadImg)
        vue.component("uploadFile", uploadFile)
        vue.component("VerifyCode", VerifyCode)
        vue.component("eb-input-number", InputNumber);
        vue.component('detail-header',DetailHeader);
        vue.component('eb-filter-user-list',FilterUserInput);
        vue.component('eb-date-range',DateRange);
        vue.component('eb-operation',OperationComponent);
        
    }
}

export default myCompnents;