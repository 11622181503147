// 配置编译环境和线上环境之间的切换 

const env = process.env;
let baseUrl = '';
let downloadUrl = '';

let mapKey = 'DBRBZ-MSM64-7PGUC-DXQBQ-D2RCE-54BNY';

if (env.NODE_ENV == 'development' || env.VUE_APP_ENV == 'test') {
     baseUrl = 'https://test.eanpower.com/api';
     baseUrl = "/api"
} else if (env.NODE_ENV == 'production') {
     baseUrl = 'https://station.eanpower.com/api';
}
   //baseUrl = 'https://station.eanpower.com/api';
   baseUrl = 'https://test.eanpower.com/api';
export {
     baseUrl,
     downloadUrl,
     env,
     mapKey
}