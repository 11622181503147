<!-- 输入框只能输入正数 -->
<template>
  <el-input
    v-model="curValue"
    @input="handleInput"
    @change="handleChange"
    :placeholder="placeholder"
    :maxLength="maxLength"
  >
    <template #suffix>
      <slot name="suffix"></slot>
    </template>
  </el-input>
</template>

<script>
import { reg } from "@/common/validate";
import { validatenull } from "../../common/validate";

export default {
  /*
   * 'value', 'max', 'min', 'double'
   * */
  emits: ["update:modelValue"],
  props: {
    placeholder: {
      type: String,
    },
    value: {
      type: [String, Number], // \d*
      default: "",
    },
    max: {
      //比较数值
      type: Number,
    },
    min: {
      // 比较数值
      type: Number,
    },
    maxLength: {
      //校验数的位数
      type: Number,
    },
    minLength: {
      //校验数的位数
      type: Number,
    },
    double: {
      //如果为true则支持输入小数
      type: Boolean,
    },
  },
  data() {
    return {
      curValue: "",
    };
  },

  methods: {
    handleChange() {},
    handleInput(e) {
      let flag = false;
      if (this.double && reg.unsignedFloat.test(e)) {
        flag = true;
      } else if (/^(-?\d+$|\d*)$/.test(e)) {
        // 只能输入数字
        flag = true;
      }

      let value = Number(e);
      if (!validatenull(this.max) && this.max < value) {
        flag = false;
      }

      if (!validatenull(this.min) && this.min > value) {
        flag = false;
      }

      if (flag || this.validatenull(e)) {
        this.curValue = e;
        this.$emit("update:modelValue", this.curValue);
      }
    },
  },
  watch: {
    value: function (newValue, oldValue) {
      this.curValue = newValue;
    },
  },
};
</script>
