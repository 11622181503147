import request from '@/common/axios';
import store from '@/store';

// 电动车列表
export function fetchBicyclePage(data) {
  return request({
    url: '/web/admin/bicycle/page',
    method: 'POST',
    data: {
        ...data
    }
  });
}


// 电动车列表
export function fetchBicycleOrderPage(data) {
    return request({
      url: '/web/admin/bicycle/order/page',
      method: 'POST',
      data: {
          ...data
      }
    });
  }

  /***** 账号管理 *****/
  
// 站长账号列表
export function fetchAdminList(data) {
  return request({
      url: '/web/admin/bicycle/account/page',
      method: 'POST',
      data: {
          ...data
      }
  });
}

export function delAdmin(data) {
  return request({
      url: '/web/admin/bicycle/account/delete',
      method: 'POST',
      data: {
          ...data
      }
  });
}

export function updateAdmin(data) {
  return request({
      url: '/web/admin/bicycle/account/update',
      method: 'POST',
      data: {
          ...data
      }
  });
}


// 添加代理账号
export function accountAdd(data) {
  return request({
      url: '/web/admin/bicycle/account/add',
      unsys: true,
      method: 'POST',
      data: data
    });
}

// 修改代理账号
export function accountUpdate(data) {
  return request({ 
      url: '/web/admin/agent/account/update',
      unsys: true,
      method: 'POST',
      responseType: 'arraybuffer',
      data: data
    });
}


// 代理管理
export function fetchBicycleAgentAllList(data) {
  return request({
      url: '/web/admin/bicycle/agent/all/list',
      method: 'POST',
      data: {
          ...data
      }
  });
}


// 站长列表
export function fetchBicycleAgentList(data) {
  return request({
      url: '/web/admin/bicycle/agent/page',
      method: 'POST',
      data: {
          ...data
      }
  });
}

// 添加代付站长
export function addBicycleAgent(data){
  return request({
      url: '/web/admin/bicycle/agent/add',
      method: 'POST',
      data: {
          ...data
      }
  });
}

// 修改代付站长
export function updateBicycleAgent(data){
  return request({
      url: '/web/admin/bicycle/agent/update',
      method: 'POST',
      data: {
          ...data
      }
  });
}

// 电动车换电记录
export function fetchBicycleBatteryPage(data){
  return request({
      url: '/web/admin/bicycle/battery/page',
      method: 'POST',
      data: {
          ...data
      }
  });
}