import store from '@/store';
import { ElMessageBox } from 'element-plus'
import { ADMIN_OPERATION } from '@/const';

const myDirective = {
  install(vue, option) {
    vue.directive('auth', {
      mounted: function (el, binding) {
        return false;
        if (binding.arg === 'show' && !store.getters.permissions[binding.value]) { //展示，但是不给与操作权限 v-auth:show
          el.addEventListener('click', function (e) {
            e.stopPropagation();
            ElMessageBox.alert('没有操作权限，请联系管理员!', '没有权限', { type: 'warning' });
            return false;
          }, true)
        } else if (el.parentNode && !store.getters.permissions[binding.value]) {
          el.parentNode.removeChild(el)
        }
      }
    });


    vue.directive('operation', {
      mounted: function (el, binding) {
        let operationId = store.getters.userInfo.operationId;
        if (ADMIN_OPERATION !== operationId) {
          el.parentNode.removeChild(el)
        }
      }
    });
  }
}

export default myDirective;