import { per } from '@/const/permission';

export default function (IGNORE) {
    return {
        title: '运营管理',
        name: 'operation',
        icon: 'icon-operation',
        children: [
            {
                title: '套餐设置',
                path: '/setting/product',
                name: 'setting-product',
                component: 'setting/product',
                permission: per.SHOW_PRODUCT_LIST,
                isMenu: true
            },
            {
                title: '用户奖励金设置',
                path: '/operation/userPusherSetting',
                name: 'operation-userPusherSetting',
                component: 'operation/userPusherSetting',
                permission: per.SHOW_AWARD_SETTING,
                isMenu: true
            },
            {
                title: '押金设置',
                path: '/setting/deposit',
                name: 'setting-deposit',
                component: 'setting/deposit',
                permission: per.SHOW_DEPOSIT_SETTING_LIST,
                isMenu: true
            },
            {
                title: 'vip设置',
                path: '/setting/vip',
                name: 'setting-vip',
                component: 'setting/vipSetting',
                permission: per.SHOW_DEPOSIT_SETTING_LIST,
                isMenu: true
            },
            // {
            //     title: '代理商奖励设置',
            //     path: '/setting/agentBonus',
            //     name: 'setting-agentBonus',
            //     component: 'setting/agentBonus',
            //     permission: per.SHOW_AWARD_LIST,
            //     isMenu: true
            // }, 
            {
                title: '消息管理',
                path: '/operation/message',
                name: 'operation-message',
                component: 'operation/message',
                permission: per.SHOW_MESSAGE_LIST,
                isMenu: true
            }, {
                title: '优惠券设置',
                path: '/operation/coupon',
                name: 'operation-coupon',
                component: 'operation/coupon',
                permission: per.SHOW_COUPON_LIST,
                isMenu: true
            }, {
                title: '套餐暂停管理',
                path: '/operation/product/pause/setting',
                name: 'operation-userPauseSetting',
                component: 'operation/userPauseSetting',
                permission: per.SHOW_PAUSE_SETTING,
                isMenu: true
            }, {
                title: '新用户优惠券设置',
                path: '/operation/newUserCoupon',
                name: 'operation-newUserCoupon',
                component: 'operation/newUserCoupon',
                permission: per.SHOW_NEW_USER_SETTING,
                isMenu: true
            }, {
                title: '充电设置',
                path: '/operation/recharge',
                name: 'operation-recharge',
                component: 'operation/recharge',
                permission: per.SHOW_NEW_USER_SETTING,
                isMenu: true
            },

        ]
    }
}
