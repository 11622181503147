import request from '@/common/axios';
import store from '@/store';
// 站点列表管理
export function fetchStationList(data) {

  return request({
    url: '/web/admin/station/stationPage',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 站点列表管理
export function fetchStationListAll(data) {

  return request({
    url: '/web/admin/station/getStationList',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 添加
export function addStation(data) {
  return request({
    url: '/web/admin/station/add',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 删除站点
export function delStation(data) {
  return request({
    url: '/web/admin/station/disable',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 修改站点  /web/admin/station/info
export function updateStation(data) {
  return request({
    url: '/web/admin/station/edit',
    method: 'POST',
    data: {
      ...data
    }
  });
}

//获取站点详细信息
export function stationDetail(data) {
  return request({
    url: '/web/admin/station/info?stationMacId='+data.stationMacId,
    method: 'get',
  });
}

// 启用禁用站点
export function updateStationStatus(data) {
  return request({
    url: '/station/disable',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 站点电池
export function fetchStationBatteryList(data) {
  return request({
    url: '/web/admin/station/info?stationMacId='+data.stationMacId,
    method: 'get',
  });
}

// 电池柜警告信息
export function fetchStationWarningList(data) {
  return request({
    url: '/web/admin/station/StationWarnPage',
    method: 'POST',
    data: {
      ...data,
    }
  });
}


/** 比特安换电api */

// 修改比特安换电阈值
export function btaUpdateChangeValue(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 4402,
      oReqData: {
        ...data
      }
    }
  });
}

// 修改比特安音量
export function btaUpdateChangeVolume(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 4403,
      oReqData: {
        ...data
      }
    }
  });
}

// 修改比特安配置 
export function btaUpdateChangeSetting(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 4405,
      oReqData: {
        ...data
      }
    }
  });
}

// 查询比特安配置
export function btaFetchChangeSetting(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 4404,
      oReqData: {
        ...data
      }
    }
  });
}

// 查询格子电量
export function fetchCabinetBoxInfo(data) {
  return request({
    url: '/platform',
    method: 'post',
    loading: false,
    data: {
      "iReqCode": 3405,
      "oReqData": {
        "query": {
          ...data
        },
        "page": 1,
        "size": 20,
      }
    }
  })
}

// 站点设备列表
export function batteryListByStation(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 1305,
      oReqData: {
        "query": {
          "endTime": "",
          "startTime": "",
          "macid": "",
          "updateAt": "",
          "type": -1,
          "outAdminId": -1,
          "createAt": "",
          "inAdminId": -1,
          "deviceUserId": -1,
          "operationId": -1,
          "outTime": "",
          "stationId": -1,
          "status": -1,
          ...data
        },
        "page": 1,
        "size": 999,
      }
    }
  });
}

// 查询换电柜是否在线
export function fetchCabinetOnline(data) {
  return request({
    url: '/platform',
    method: 'post',
    data: {
      "iReqCode": 4508,
      "oReqData": data
    }
  })
}

// 查询柜子详细信息
export function fetchCabinetInfo(data) {
  return request({
    url: '/platform',
    method: 'post',
    data: {
      "iReqCode": 3617,
      "oReqData": { "stationMacid": data }
    }
  })
}

// 远程打开仓门
export function btaOpenDoor(data) {
  return request({
    url: '/platform',
    method: 'post',
    data: {
      "iReqCode": 4406,
      "oReqData": data
    }
  })
}

// 比特安设置
export function btaUpdateSetting(data) {
  return request({
    url: '/platform',
    method: 'post',
    data: {
      "iReqCode": 4405,
      "oReqData": data
    }
  })
}

// 换电柜电表记录
export function fetchAmmeters(data) {
  return request({
    url: '/web/admin/station/stationDaySumPage',
    method: 'post',
    data: data
  })
}

// 换电柜电表记录
export function fetchAmmetersCount(data) {
  return request({
    url: '/web/admin/station/stationDaySumCount',
    method: 'post',
    data: data
  })
}

// 导出电表数据
export function exportAmmetersCount(data) {
  return request({
    url: '/web/admin/station/stationDaySumCount/export',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: data
  });
}


// 导出电表数据
export function exportAmmeters(data) {
  return request({
    url: '/excel/dayNum/down',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: {
      "iReqCode": 0,
      "oReqData": data,
    }
  });
}

// 导出站点电池记录
export function exportStationBattery(data) {
  return request({
    url: '/excel/stationDevice/down',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: {
      "iReqCode": 0,
      "oReqData": data,
    }
  });
}

// 站点禁用列表
export function fetchStationCabinetBanList(data) {
  return request({
    url: '/web/admin/station/cabinet/ban/list',
    unsys: true,
    method: 'POST',
    data: data
  });
}

/** 点位管理 */

// 点位列表
export function fetchPointPage(data) {
  return request({
    url: '/web/admin/point/page',
    method: 'POST',
    data: data
  });
}

// 添加点位
export function addPoint(data) {
  return request({
    url: '/web/admin/point/add',
    method: 'POST',
    data: data
  });
}


// 编辑列表
export function updatePoint(data) {
  return request({
    url: '/web/admin/point/update',
    method: 'POST',
    data: data
  });
}


// 删除列表
export function delPoint(data) {
  return request({
    url: '/web/admin/point/delete',
    method: 'POST',
    data: data
  });
}

// 删除列表
export function fetchPointAll(data) {
  return request({
    url: '/web/admin/point/all',
    method: 'POST',
    data: data
  });
}


/* **********  点位账号管理  *********** */

export function fetchProviderPage(data) {
  return request({
    url: '/web/admin/provider/page',
    method: 'POST',
    data: data
  });
}

// 添加点位
export function addProvider(data) {
  return request({
    url: '/web/admin/provider/add',
    method: 'POST',
    data: data
  });
}


// 编辑列表
export function updateProvider(data) {
  return request({
    url: '/web/admin/provider/update',
    method: 'POST',
    data: data
  });
}


// 删除列表
export function delProvider(data) {
  return request({
    url: '/web/admin/provider/delete',
    method: 'POST',
    data: data
  });
}

// 查询全部的提供方
export function fetchProviderAll(data) {
  return request({
    url: '/web/admin/provider/all',
    method: 'POST',
    data: data
  });
}
