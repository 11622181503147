import { per } from '@/const/permission';

export default function (IGNORE) {
    return {
        title: '换电数据',
        name: 'statistics',
        icon: 'icon-user',
        children: [
        {
            title: '换电耗电统计',
            path: '/statistics/station-statistics',
            name: 'station-statistics',
            component: 'statistics/station-statistics',
            permission: per.SHOW_EXCHANGE_AMMETER,
            isMenu: true
        },{
            title: '日电表读数',
            path: '/station/ammeters',
            name: 'station-ammeters',
            component: 'station/ammeters',
            permission: per.SHOW_DAY_AMMETER,
            isMenu: true
        },{
            title: '耗电量统计',
            path: '/station/ammeters/count',
            name: 'station-ammeter-counts',
            component: 'station/ammetersCount',
            permission: IGNORE,
            isMenu: true
        },{
            title: '订单统计',
            path: '/order/statistics/month',
            name: 'statistics-order',
            component: 'user/order-statistics',
            permission: per.SHOW_DAY_AMMETER,
            isMenu: true
        }]
    }
}
