<template>
    <div class="detail-header">
        <i class="iconfont icon-xiangzuo back" @click="goBack"></i>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },

    methods: {
        goBack() {
            this.$router.back();
        }
    }
}
</script>

<style scoped>
    .detail-header{
        background: #fff;
    }

    .back{
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
    }
</style>