/*
* 路由菜单配置
* */

/**
 * 路由控制和菜单控制
 * 1、路由会把所有 有component字段 并且有权限的组件加入到路由，
 * 2、路由控制全部使用绝对布局，不分层级
 * 3、菜单会按层级渲染所有的isMenu : true 的节点
 *
 * */
import global from './global';
import board from './board';
import agent from './agent';
import device from './device';
import order from './order';
import user from './user';
import wallet from './wallet';
import station from './station';
import setting from './setting';
import statistics from './statistics';
import cabinet from './cabinet';
import operation from './operation';
import system from './system';
import online from './online';
import active from './active';
import recharge from './recharge';
import zzAgent from './zzAgent';
import bicycle from './bicycle';

export const IGNORE = "ignore"; //忽略权限
export const menuList = [
      board(IGNORE),
      //recharge(IGNORE),
      device(IGNORE),
      active(IGNORE),
      bicycle(IGNORE),
      statistics(IGNORE),
     // station(IGNORE),
     online(IGNORE),
     cabinet(IGNORE),
     order(IGNORE),
     system(IGNORE),
     user(IGNORE),
     operation(IGNORE),
     //wallet(IGNORE),
     agent(IGNORE),
     zzAgent(IGNORE),
]
export const globalList = [global(IGNORE)];
