//岗位管理权限
export const per = {
    "SHOW_DATA_BOARD": "SHOW_DATA_BOARD", // 数据看板

    "SHOW_CABINET_WARNING":"SHOW_CABINET_WARNING", //查看异常警报
    "SHOW_CABINET_BAN": "SHOW_CABINET_BAN", // 仓门禁用列表

    /******** 电池 ********/
    "SHOW_BATTERY_LIST": "SHOW_BATTERY_LIST",//查看电池列表
    "SHOW_BATTERY_DETAIL": "SHOW_BATTERY_DETAIL", //查看电池详情
    "BATTERY_IMPORT": "BATTERY_IMPORT",//导入电池
    "BATTERY_TEMPLATE": "BATTERY_TEMPLATE",//下载导入模板
    "SHOW_BATTERY_DETAIL": "SHOW_BATTERY_DETAIL", // 查看电池详情
    "EXPORT_DEVICE_LIST" : "EXPORT_DEVICE_LIST", // 导出电池列表

    "SHOW_BATTERY_ERROR":"SHOW_BATTERY_ERROR", // 查看跳电电池
    
    /*********** 换电统计 *********/
    "SHOW_AVG_EXCHANGE":"SHOW_AVG_EXCHANGE", // 查看平均换电数
    "EXPORT_AVG_EXCHANGE": "EXPORT_AVG_EXCHANGE", // 导出平均换电数

    /** 平均耗电统计 */
    "EXPORT_EXCHANGE_AMMETER": "EXPORT_EXCHANGE_AMMETER", // 导出耗电统计
    "SHOW_EXCHANGE_AMMETER": "SHOW_EXCHANGE_AMMETER", // 查看换电耗电统计

    /** 查看用户订单统计 */
    "SHOW_USER_ORDER_STATISTICS": "SHOW_USER_ORDER_STATISTICS", //查看用户订单统计

    /** 用户月报表 */
    "SHOW_USER_ORDER_STATISTICS_MONTH": "SHOW_USER_ORDER_STATISTICS_MONTH", // 查看用户月报表
    "EXPORT_ORDER_STATISTICS_MONTH": "EXPORT_ORDER_STATISTICS_MONTH", // 导出用户月报表

    /** 日报表读数 */
    "SHOW_DAY_AMMETER" : "SHOW_DAY_AMMETER", // 查看日电表读数


    "AGENT_MANAGE": "AGENT_MANAGE",//代理商管理
    "STATION_MANAGE": "STATION_MANAGE",//站点管理
    "BATTERY_MANAGE": "BATTERY_MANAGE",//电池管理
    "OPERATION_MANAGE": "OPERATION_MANAGE",//运营管理
    "ORDER_MANAGE": "ORDER_MANAGE",//订单管理
    "SYS_USER_MANAGE": "SYS_USER_MANAGE",//系统用户
    "USER_MANAGE": "USER_MANAGE",//用户管理
    "WALLET_MANAGE": "WALLET_MANAGE",//资金管理
    "SYS_SETTING": "SYS_SETTING",//系统设置

    "AGNET_LIST": "AGNET_LIST",//代理商列表
    "AGENT_ACCOUNT": "AGENT_ACCOUNT",//代理商账户管理
    "AGENT_EMPLOYEE": "AGENT_EMPLOYEE",//代理商员工管理

    /* 代理商管理 */
    "SHOW_AGENT_LIST": "SHOW_AGENT_LIST",//查看代理商列表
    "AGENT_ADD": "AGENT_ADD",//添加代理商
    "AGENT_UPDATA": "AGENT_UPDATA",//修改代理商
    "AGENT_DEL": "AGENT_DEL",//删除代理商
    "SHOW_AGENT_INCOME": "SHOW_AGENT_INCOME", // 查看代理商收益
    "SHOW_AGENT_INCOME_STATICTICS":"SHOW_AGENT_INCOME_STATICTICS", // 查看代理商收益统计
    
    "SHOW_AGENT_USER_CHARGE":"SHOW_AGENT_USER_CHARGE",// 用户充值记录
    "SHOW_AGENT_USER": "SHOW_AGENT_USER", // 查看代理用户列表
    "SHOW_AGENT_ASSETS":"SHOW_AGENT_ASSETS",// 查看资产列表
    "SHOW_AGENT_CHARGE":"SHOW_AGENT_CHARGE", // 查看充值记录
     "SHOW_AGENT_ACCOUNT": "SHOW_AGENT_ACCOUNT",//查看代理商账户管理
    // "AGENT_ACCOUNT_ADD": "AGENT_ACCOUNT_ADD",//添加代理商账户
    // "AGENT_ACCOUNT_UPDATA": "AGENT_ACCOUNT_UPDATA",//修改代理商账户
    // "AGENT_ACCOUNT_DEL": "AGENT_ACCOUNT_DEL",//删除代理商账户

    "SHOW_AGENT_EMPLOYEE": "SHOW_AGENT_EMPLOYEE",//查看代理商员工列表
    "AGENT_EMPLOYEE_ADD": "AGENT_EMPLOYEE_ADD",//添加代理商员工
    "AGENT_EMPLOYEE_UPDATA": "AGENT_EMPLOYEE_UPDATA",//修改代理商员工
    "AGENT_EMPLOYEE_DEL": "AGENT_EMPLOYEE_DEL",//删除代理商员工

    "STATION_LISE": "STATION_LISE",//站点列表
    "STATION_BATTERY": "STATION_BATTERY",//站点电池

    "SHOW_STATION_LIST": "SHOW_STATION_LIST",//查看站点列表
    "STATION_ADD": "STATION_ADD",//添加站点
    "STATION_UPDATE": "STATION_UPDATE",//修改站点
    "STATION_DEL": "STATION_DEL",//删除站点
    "STATION_AUDIT": "STATION_AUDIT",//站点审核
    "SHOW_STATION_DETAIL": "SHOW_STATION_DETAIL",//站点详情

    "SHOW_STATION_BATTERY": "SHOW_STATION_BATTERY",//查看站点电池
    "EXPORT_STATION_BATTERY": "EXPORT_STATION_BATTERY", // 导出站点电池

    "BATTERY_LIST": "BATTERY_LIST",//电池列表

    
    "MESSAGE_LIST": "MESSAGE_LIST",//消息管理
    "COUPON_SETTING": "COUPON_SETTING",//优惠券设置
    
    /* 公告管理 */
    "SHOW_MESSAGE_LIST": "SHOW_MESSAGE_LIST",//查看消息列表
    "MESSAGE_ADD": "MESSAGE_ADD",//添加消息
    "MESSAGE_UPDATA": "MESSAGE_UPDATA",//修改消息
    "MESSAGE_DEL": "MESSAGE_DEL", // 删除消息

    /* 优惠券设置 */
    "SHOW_COUPON_LIST": "SHOW_COUPON_LIST",//查看优惠券列表
    "COUPON_LIST_ADD": "COUPON_LIST_ADD",//添加优惠券
    "COUPONT_UPDATE": "COUPONT_UPDATE",//修改优惠券
    "COUPONT_PUTWAY": "COUPONT_PUTWAY",//上架下架

    /* 套餐设置 */
    "SHOW_PRODUCT_LIST": "SHOW_PRODUCT_LIST",//查看套餐设置
    "PRODUCT_ADD": "PRODUCT_ADD",//添加套餐
    "PRODUCT_UPDATA": "PRODUCT_UPDATA",//修改套餐
    "PRODUCT_PUT_WAY": "PRODUCT_PUT_WAY",//上架下架套餐

    /* 违约规则 */
    "SHOW_PENALTY_RULE": "SHOW_PENALTY_RULE",//查看违约规则

    /** 押金设置 */
    "SHOW_DEPOSIT_SETTING_LIST": "SHOW_DEPOSIT_SETTING_LIST",//查看押金设置
    "DEPOSIT_ADD": "DEPOSIT_ADD",//添加押金设置
    "DEPOSIT_UPDATA": "DEPOSIT_UPDATA",//修改押金设置
    "DEPOSIT_PUT_WAY": "DEPOSIT_PUT_WAY",//上架下架押金

    /** 代理商奖励设置 */
    "SHOW_AWARD_LIST": "SHOW_AWARD_LIST",//查看奖励设置
    "AWARD_ADD": "AWARD_ADD",//添加奖励设置
    "AWARD_UPDATA": "AWARD_UPDATA",//修改奖励设置
    "AWARD_DISABLED": "AWARD_DISABLED",//启用禁用


    "ORDER_LIST": "ORDER_LIST",//套餐购买记录
    "EXCHANGE_RECORD": "EXCHANGE_RECORD",//换电记录
    "PENALTY_RECORD": "PENALTY_RECORD",//违约记录

    "SHOW_PRODUCT_RECORD": "SHOW_PRODUCT_RECORD",//查看套餐购买记录
    "SHOW_ORDER_REFUND": "SHOW_ORDER_REFUND", // 查看退款记录
    "EXPORT_PRODUCT_RECORD": "EXPORT_PRODUCT_RECORD", // 导出购买套餐记录


    "SHOW_EXCHANGE_RECORD": "SHOW_EXCHANGE_RECORD",//查看换电记录
    "EXPORT_EXCHANGE_RECORD": "EXPORT_EXCHANGE_RECORD", // 导出换电记录

    "SHOW_PENALTY_RECORD": "SHOW_PENALTY_RECORD",//查看违约记录
    "SHOW_OPEN_DOOR_RECORD" : "SHOW_OPEN_DOOR_RECORD", //查看开门记录
    "EXPORT_OPEN_DOOR_RECORD": "EXPORT_OPEN_DOOR_RECORD", // 导出开门记录

    "SHOW_PAUSE_SETTING":"SHOW_PAUSE_SETTING", // 暂停套餐设置
    "SHOW_NEW_USER_SETTING": "SHOW_NEW_USER_SETTING", // 用户用户优惠券
    /** 系统角色账户设置 */
    "SYS_ACCOUNT": "SYS_ACCOUNT",//系统账号管理
    "SYS_ROLE": "SYS_ROLE",//系统角色管理
    "PERMISSION_LIST": "PERMISSION_LIST",//权限资源管理

    "SHOW_SYS_USER": "SHOW_SYS_USER",//查看系统账户列表
    "SYS_USER_ADD": "SYS_USER_ADD",//添加系统账号
    "SYS_USER_UPDATA": "SYS_USER_UPDATA",//修改系统账号
    "SYS_USER_DEL": "SYS_USER_DEL",//删除系统用户

    "SHOW_ROLE_LIST": "SHOW_ROLE_LIST",//查看角色列表
    "ROLE_ADD": "ROLE_ADD",//添加角色
    "ROLE_UPDATA": "ROLE_UPDATA",//修改角色
    "ROLE_DEL": "ROLE_DEL",//删除角色
    "ROLE_PERMISSION_UPDATA": "ROLE_PERMISSION_UPDATA",//修改角色权限

    "SHOW_PERMISSION_TREE": "SHOW_PERMISSION_TREE",//查看资源菜单
    "PERMISSION_ADD": "PERMISSION_ADD",//添加权限菜单
    "PERMISSION_UPDATA": "PERMISSION_UPDATA",//修改权限菜单
    "PERMISSION_DEL": "PERMISSION_DEL",//删除资源


    "USER_LIST": "USER_LIST",//用户列表

    "SHOW_USER_LIST": "SHOW_USER_LIST",//查看用户列表
    "SHOW_USER_DETAIL":"SHOW_USER_DETAIL", // 查看用户详情
    "USER_PUSHER_SET" : "USER_PUSHER_SET", // 设置推广员
    "UPDATE_USER": "UPDATE_USER", // 修改用户

    "DEPOSIT_LIST": "DEPOSIT_LIST",//退还押金
    
    "AGENT_WITHDRAW": "AGENT_WITHDRAW",//代理商提现
    "SHOW_WITHDRAW_AUDIT": "SHOW_WITHDRAW_AUDIT",//查看代理商提现
    "WITHDRAW_AUDIT": "WITHDRAW_AUDIT",//代理商提现审核

    "SHOW_DEPOSIT_LIST": "SHOW_DEPOSIT_LIST",//查看退还押金列表
    "DEPOSIT_AUDIT": "DEPOSIT_AUDIT",//押金退款审核
    "DEPOSIT_AUDIT_CONFIRM": "DEPOSIT_AUDIT_CONFIRM", // 退款确认

    "PRODUCT_LIST": "PRODUCT_LIST",//套餐设置
    "PENALTY_SETTING": "PENALTY_SETTING",//违约金设置
    "DEPOSIT_SETTING": "DEPOSIT_SETTING",//押金设置
    "SHOW_AWARD_SETTING": "SHOW_AWARD_SETTING",//代理商奖励设置

    "SHOW_USER_ASSETS_DETAIL":"SHOW_USER_ASSETS_DETAIL",// 用户资产详情
    "SHOW_USER_ASSETS": "SHOW_USER_ASSETS", // 查看用户资产
    "SHOW_USER_INVITE":"SHOW_USER_INVITE", // 用户邀请记录
    "SHOW_PRODUCT_PAUSE":"SHOW_PRODUCT_PAUSE", // 用户暂停列表
    "SHOW_USER_OVERDUE": "SHOW_USER_OVERDUE", // 用户逾期信息
    "EXPORT_USER_OVERDUE": "EXPORT_USER_OVERDUE", // 导出用户逾期信息

    "SHOW_USER_PUSHER_LIST": "SHOW_USER_PUSHER_LIST", // 用户奖励金
    "EXPORT_USER_PUSHER_LIST": "EXPORT_USER_PUSHER_LIST", // 导出用户奖励金
    "SHOW_USER_WITHDRAW": "SHOW_USER_WITHDRAW", // 推广员提现
    "EXPORT_USER_WITHDRAW": "EXPORT_USER_WITHDRAW", // 导出推广员提现记录
    "USER_WITHDRAW_CONFIRM": "USER_WITHDRAW_CONFIRM", //确认提现
    "USER_WITHDRAW_AUDIT": "USER_WITHDRAW_AUDIT", // 提现审核

    "SHOW_USER_CONPON": "SHOW_USER_CONPON", // 用户优惠券
    "ADD_USER_COUPON":"ADD_USER_COUPON",// 优惠券发放


    /** 活动管理 */
    "UNICOM_REG_SEND_AWARD": "UNICOM_REG_SEND_AWARD", // 发放奖励
    "UNICOM_REG_REMARK": "UNICOM_REG_REMARK", // 增加活动备注
    "SHOW_UNICOM_REG": "SHOW_UNICOM_REG", // 查看联通活动登记表

    /**联通活动套餐管理 */
    "SHOW_UNICOM_PRODUCT": "SHOW_UNICOM_PRODUCT", // 查看联通活动套餐
}
