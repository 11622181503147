import { getStore, setStore, clearStore } from '@/common/store';
import { fetchAgentListAll } from '@/api/agent';
import { validatenull } from "@/common/validate";
import {ADMIN_OPERATION} from '@/const'
import store from '../index';

const agent = {
    state: {
        agentList: getStore({
            name: 'agentList'
        }) || { map: {}, list: [] },
    },
    actions: {
        fetchAgentListAll({ commit, state, dispatch }, params) {
            return new Promise((solve, reject) => {
                // 代理商不能查询其他代理商
                // if (store.state.user.userInfo.operationId !== ADMIN_OPERATION) {
                //     commit('SET_AGENT_LIST', []);
                //     solve();
                // } else {
                    fetchAgentListAll().then(res => {
                        console.log("res,fetchAgentListAll",res);
                        commit('SET_AGENT_LIST', res.data.data);
                        solve();
                    })
               // }
            })

        },
    },
    mutations: {
        SET_AGENT_LIST(state, agentList) {
            //state.agentList.invalid = false;
            state.agentList = agentList;
            setStore({
                name: 'agentList',
                content: state.agentList,
                type: 'session'
            })
        },
    }
}

export default agent;
