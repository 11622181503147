import { per } from '@/const/permission';

export default function (IGNORE) {
    return {
        title: '用户管理',
        name: 'user',
        icon: 'icon-user',
        children: [{
            title: '用户列表',
            path: '/user/list',
            name: 'user-list',
            component: 'user/list',
            isMenu: true,
            permission: per.SHOW_USER_LIST,
            meta: {
                keepAlive: true
            }
        },{
            title: '用户详情',
            path: '/user/detail/:id',
            name: 'user-detail',
            component: 'user/detail',
            permission: per.SHOW_USER_LIST,
            isMenu: false,
        }, {
            title: '用户逾期信息',
            path: '/user/overdue',
            name: 'user-coupoverduen',
            component: 'user/overdue',
            permission: per.SHOW_USER_OVERDUE,
            isMenu: true,
        },{
            title: '超过3天未换电',
            path: '/user/overBattery',
            name: 'user-over-battery',
            component: 'user/overBattery',
            permission: per.SHOW_USER_OVERDUE,
            isMenu: true,
        }, {
            title: '用户优惠券',
            path: '/user/coupon',
            name: 'user-coupon',
            component: 'user/coupon',
            permission: per.SHOW_USER_COUPONT,
            isMenu: true,
        },{
            title: '积分记录',
            path: '/user/score',
            name: 'user-score',
            component: 'user/score',
            permission: per.SHOW_USER_OVERDUE,
            isMenu: true,
        }, {
            title: '套餐暂停列表',
            path: '/user/stopProduct',
            name: 'user-stop',
            component: 'user/stopProduct',
            permission: per.SHOW_PRODUCT_PAUSE,
            isMenu: true,
        }, {
            title: '用户邀请记录',
            path: '/user/invite',
            name: 'user-invite-record',
            component: 'user/invite',
            permission: per.SHOW_USER_INVITE,
            isMenu: true,
        }, {
            title: '用户资产',
            path: '/user/assets',
            name: 'user-assets',
            component: 'user/asset',
            permission: per.SHOW_USER_ASSETS,
            meta: {
                keepAlive: true
            },
            isMenu: true,
        }, {
            title: '用户资产详情',
            path: '/user/assets/detail',
            name: 'user-assets-detail',
            component: 'user/asset-detail',
            permission: per.SHOW_USER_ASSETS_DETAIL,
            isMenu: true,
        },  {
            title: '押金扣除记录',
            path: '/user/deposit/subtract',
            name: 'deposit-subtract',
            component: 'user/deposit-subtract-list',
            permission: per.SHOW_PRODUCT_PAUSE,
            isMenu: true,
        }, {
            title: '用户提现列表',
            path: '/user/assets/withdraw',
            name: 'user-assets-withdraw',
            component: 'user/asset-withdraw',
            permission: per.SHOW_USER_WITHDRAW,
            isMenu: true,
        }, {
            title: '站点推广员列表',
            path: '/user/pusher-list',
            name: 'user-pusher-list',
            component: 'user/pusher-list',
            permission: per.SHOW_USER_PUSHER_LIST,
            isMenu: true,
            meta: {
                keepAlive: true
            },
        }]
    }
}