import request from '@/common/axios';


// 站长列表
export function fetchAgentList(data) {
    return request({
        url: '/web/admin/agent/page',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 添加代付站长
export function addAgent(data){
    return request({
        url: '/web/admin/agent/add',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 修改代付站长
export function updateAgent(data){
    return request({
        url: '/web/admin/agent/update',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 站长账号列表
export function fetchAdminList(data) {
    return request({
        url: '/web/admin/agent/account/page',
        method: 'POST',
        data: {
            ...data
        }
    });
}

export function delAdmin(data) {
    return request({
        url: '/web/admin/agent/account/delete',
        method: 'POST',
        data: {
            ...data
        }
    });
}

export function updateAdmin(data) {
    return request({
        url: '/web/admin/agent/account/update',
        method: 'POST',
        data: {
            ...data
        }
    });
}

/******** 站长代理代付订单列表 ************/
export function fetchAgentOrder(data) {
    return request({
        url: '/web/admin/agent/order/page',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 手动结算代付订单
export function aentOrderSettle(data) {
    return request({
        url: '/web/admin/agent/order/settle',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 站长代理用户   
export function fetchAgentUserPage(data) {
    return request({
        url: '/web/admin/agent/user/page',
        method: 'POST',
        data: {
            ...data
        }
    });
}


// 所有站长代理
export function fetchZZAgentList(data) {
    return request({
        url: '/web/admin/agent/all/list',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 导出代付订单列表
export function ZZAgentOrderExport(data) {
    return request({
        url: '/web/admin/agent/order/exportOrder',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: data
      });
}


// 添加代理账号
export function ZZAgentAccountAdd(data) {
    return request({
        url: '/web/admin/agent/account/add',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: data
      });
}

// 修改代理账号
export function ZZAgentAccountUpdate(data) {
    return request({
        url: '/web/admin/agent/account/update',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: data
      });
}


// 结算详情
export function ZZAgentSettlePage(data) {
    return request({
        url: '/web/admin/agent/order/settle/page',
        method: 'POST',
        data: data
      });
}

// 导出结算详情
export function ZZAgentSettleExport(data) {
    return request({
        url: '/web/admin/agent/order/settle/export',
        method: 'POST',
        responseType: 'arraybuffer',
        unsys: true,
        data: data
      });
}