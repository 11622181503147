<!-- 代理商分别界面，如果是代理商则不显示子组件 -->
<template>
    <slot v-if="operationId === this.const.ADMIN_OPERATION"></slot>
</template>

<script>
export default {
    data(){
        let operationId = this.$store.getters.userInfo.operationId;
        return {
            operationId: operationId
        };
    }
}
</script>