<template>
  <div class="viewport">
    <div class="column">
      <div class="overview panel">
        <div class="inner">
          <div class="item">
            <h4>{{ device_num }}</h4>
            <span>
              <i class="icon-dot" style="color: #006cff"></i>
              设备总数
            </span>
          </div>
          <div class="item">
            <h4>{{ changeCount }}</h4>
            <span>
              <i class="icon-dot" style="color: #6acca3"></i>
              换电总量
            </span>
          </div>
          <div class="item">
            <h4>{{ station_num }}</h4>
            <span>
              <i class="icon-dot" style="color: #6acca3"></i>
              网点总数
            </span>
          </div>
          <div class="item">
            <h4>{{ device_user_num }}</h4>
            <span>
              <i class="icon-dot" style="color: #6acca3"></i>
              借出设备
            </span>
          </div>
        </div>
      </div>
      <!--监控-->
      <div class="monitor panel">
        <div class="inner">
          <div class="tabs">
            <span class="active">换电站点监控</span>
          </div>
          <div class="content" style="display: block">
            <div class="head">
              <span class="col">换电时间</span>
              <span class="col">用户名</span>
              <span class="col">设备地点</span>
            </div>
            <div class="marquee-view">
              {{ item }}
              <div class="marquee" id="startList">
                <div class="row" v-for="item in realTimeData">
                  <span class="col">{{ item.startTime }}</span>
                  <span class="col">
                    {{ item.realName }}
                  </span>
                  <span class="col">
                    {{ item.province }}-{{
                      item.city
                    }}-{{ item.area }}-{{
                      item.stationName
                    }}
                  </span>
                  <span class="icon-dot"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--设备-->
      <div class="point panel">
        <div class="inner">
          <h3>设备分布统计</h3>
          <div class="chart">
            <div class="pie">
              <PieChart ref="deviceChart" :data="deviceData"></PieChart>
            </div>
            <div class="data">
              <div class="item">
                <h4>{{ device_num }}</h4>
                <span>
                  <i class="icon-dot" style="color: #ed3f35"></i>
                  设备总数
                </span>
              </div>
              <div class="item">
                <h4>{{ mouth_add }}</h4>
                <span>
                  <i class="icon-dot" style="color: #eacf19"></i>
                  本月新增
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <!-- 地图 -->
      <div class="map">
        <h3>
          <span class="icon-cube"></span>
          设备数据统计
        </h3>
        <div class="chart">
          <div class="geo">
            <MainCharts></MainCharts>
          </div>
        </div>
      </div>
      <!-- 用户 -->
      <div class="users panel">
        <div class="inner">
          <h3>新增用户数据统计</h3>
          <div class="chart">
            <div class="bar">
              <BarChart ref="userCharts"></BarChart>
            </div>
            <div class="data">
              <div class="item">
                <h4>{{ user_num }}</h4>
                <span>
                  <i class="icon-dot" style="color: #ed3f35"></i>
                  用户总量
                </span>
              </div>
              <div class="item">
                <h4 id="add_user">{{ user_add }}</h4>
                <span>
                  <i class="icon-dot" style="color: #eacf19"></i>
                  本月新增
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="order panel">
        <div class="inner">
          <!-- 筛选 -->
          <div class="filter">
            <span
              @click="getOrderRecord('365')"
              :class="orderActive === '365' ? 'active' : ''"
              >365天</span
            >
            <span
              @click="getOrderRecord('90')"
              :class="orderActive === '90' ? 'active' : ''"
              >90天</span
            >
            <span
              @click="getOrderRecord('30')"
              :class="orderActive === '30' ? 'active' : ''"
              >30天</span
            >
            <span
              @click="getOrderRecord('1')"
              :class="orderActive === '1' ? 'active' : ''"
              >24小时</span
            >
          </div>
          <!-- 数据 -->
          <div class="data">
            <div class="item">
              <h4>{{ orderData[orderActive].order_num }}</h4>
              <span>
                <i class="icon-dot" style="color: #ed3f35"></i>
                订单量
              </span>
            </div>
            <div class="item">
              <h4>{{ orderData[orderActive].trade_num }}</h4>
              <span>
                <i class="icon-dot" style="color: #eacf19"></i>
                销售额(元)
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- 订单量 -->
      <div class="sales panel">
        <div class="inner">
          <div class="caption">
            <h3>换电数统计</h3>
            <span
              @click="getExchangeRecord('year')"
              :class="exchangeActive === 'year' ? 'active' : ''"
              data-type="year"
              >年</span
            >
            <span
              @click="getExchangeRecord('quarter')"
              :class="exchangeActive === 'quarter' ? 'active' : ''"
              data-type="quarter"
              >季</span
            >
            <span
              @click="getExchangeRecord('month')"
              :class="exchangeActive === 'month' ? 'active' : ''"
              data-type="month"
              >月</span
            >
            <span
              @click="getExchangeRecord('week')"
              :class="exchangeActive === 'week' ? 'active' : ''"
              data-type="week"
              >周</span
            >
          </div>
          <div class="chart">
            <div class="label">单位:万</div>
            <div class="line">
              <LineChart
                :data="exchangeData[exchangeActive]"
                ref="lineChart"
              ></LineChart>
            </div>
          </div>
        </div>
      </div>
      <!-- 渠道 季度 -->
      <div class="wrap">
        <div class="channel panel">
          <div class="inner">
            <h3>用户来源</h3>
            <div class="data">
              <div class="item">
                <h4 id="channel_canyin">
                  {{ userSource.nature }} <small>%</small>
                </h4>
                <span>
                  <i class="icon-cube"></i>
                  自然用户
                </span>
              </div>
              <div class="item">
                <h4 id="channel_bag">{{ userSource.user }} <small>%</small></h4>
                <span>
                  <i class="icon-cube"></i>
                  用户推荐&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
            </div>
            <div class="data">
              <div class="item">
                <h4 id="channel_jiuba">
                  {{ userSource.agent }} <small>%</small>
                </h4>
                <span>
                  <i class="icon-cube"></i>
                  代理推荐
                </span>
              </div>
              <div class="item">
                <h4 id="channel_bus">
                  {{ userSource.other }} <small>%</small>
                </h4>
                <span>
                  <i class="icon-cube"></i>
                  其他&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="quarter panel">
          <div class="inner">
            <h3>本季度销售进度</h3>
            <div class="chart">
              <div class="box">
                <div class="gauge">
                  <GaugeCharts
                    ref="gaugeCharts"
                    :current="quarterTrader"
                    :target="quarterTarget"
                  ></GaugeCharts>
                </div>
                <div class="label">
                  {{ ((quarterTrader / quarterTarget) * 100).toFixed(0)
                  }}<small> %</small>
                </div>
              </div>
              <div class="data">
                <div class="item">
                  <h4>{{ quarterTrader }}</h4>
                  <span>
                    <i class="icon-dot" style="color: #6acca3"></i>
                    销售额(元)
                  </span>
                </div>
                <div class="item">
                  <h4>183%</h4>
                  <span>
                    <i class="icon-dot" style="color: #ed3f35"></i>
                    同比增长
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 排行榜 -->
      <div class="top panel">
        <div class="inner">
          <div class="all">
            <h3>全国热榜</h3>
            <ul>
              <li class="cup-li">
                <i class="icon-cup1" style="color: #d93f36"></i>
                <span class="cup-name">{{
                  rankList[0] && rankList[0].stationName
                }}</span>
              </li>
              <li class="cup-li">
                <i class="icon-cup2" style="color: #68d8fe"></i>
                <span class="cup-name">{{
                  rankList[1] && rankList[1].stationName
                }}</span>
              </li>
              <li class="cup-li">
                <i class="icon-cup3" style="color: #4c9bfd"></i>
                <span class="cup-name">{{
                  rankList[2] && rankList[2].stationName
                }}</span>
              </li>
            </ul>
          </div>
          <div class="province">
            <h3>各区热点 <i class="date">// 近30日 //</i></h3>
            <div class="data">
              <ul class="sup">
                <li
                  :class="index === rankIndex ? 'active' : ''"
                  v-for="(item, index) in areaList"
                  @mouseenter="changeOrderRankIndex(index)"
                  :key="index"
                >
                  <span>{{ item.area }}</span>
                  <span>{{ item.iTotal }}<s class="icon-up"></s></span>
                </li>
              </ul>
              <ul class="sub">
                <li v-for="(item, index) in rankList">
                  <span class="station-name">{{ item.stationName }}</span
                  ><span> {{ item.iTotal }}<s class="icon-up"></s></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "./components/pie.vue";
import BarChart from "./components/bar.vue";
import LineChart from "./components/line.vue";
import GaugeCharts from "./components/gauge.vue";
import { fetchUserEventList } from "@/api/order";
import MainCharts from "./components/mainMap.vue";
import moment from "moment";
import {
  statisticsExchangeRecord,
  exchangeRecordAll,
  newUserDay,
  statisticsAll,
  fetchUserOrder,
  fetchStationTop10,
  fetchUserSource,
} from "../../api/statistics";
import "./fonts/icomoon.css";
export default {
  components: { PieChart, BarChart, LineChart, GaugeCharts, MainCharts },
  data() {
    return {
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      quarterTrader: 0,
      quarterTarget: 150000,
      device_num: "加载中",
      exchange_num: "加载中",
      user_num: "加载中",
      user_add: "加载中",
      device_online_num: "加载中",
      device_offline_num: "加载中",

      mouth_add: "加载中",

      // 右上角的数据
      orderData: {
        365: {
          trade_num: "0",
          order_num: "0",
        },
        90: {
          trade_num: "0",
          order_num: "0",
        },
        30: {
          trade_num: "0",
          order_num: "0",
        },
        1: {
          trade_num: "0",
          order_num: "0",
        },
      },

      // 换电数统计
      exchangeActive: "year",
      exchangeData: {
        year: { label: [], value: [] },
        month: { label: [], value: [] },
        week: { label: [], value: [] },
        quarter: { label: [], value: [] },
      },

      // 用户数据统计
      userData: {
        year: { label: [], value: [] },
        month: { label: [], value: [] },
        week: { label: [], value: [] },
        quarter: { label: [], value: [] },
      },

      // 实时换电
      realTimeData: [],

      // 设备统计
      deviceData: [],

      // 用户来源
      userSource: {
        agent: 30,
        user: 58,
        other: 3,
        nature: 9,
      },

      // 订单数统计
      orderActive: "1",

      // 右下角的排名
      rankList: [{}],
      rankIndex: 0,
    };
  },
  methods: {
    // 查询右下角的排名
    getRank() {
      let queryTop = {
        startTime: "2021-11-10 15:50:26",
        endTime: "2021-11-23 15:50:26",
        city: "深圳市",
      };
      queryTop.endTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      queryTop.startTime = moment(new Date())
        .subtract(3, "days")
        .format("YYYY-MM-DD HH:mm:ss");

      fetchStationTop10(queryTop).then((res) => {
        this.rankList = res.data.data.stationTop.splice(0,6);
      });
    },

    getUserSource() {
      fetchUserSource().then((res) => {
        let data = res.data.data;
        let count =
          data.count2 + data.count1 + data.userOnlyCount + data.count3;
        this.userSource.other = Math.round((data.count2 / count) * 100).toFixed(
          0
        );
        this.userSource.nature = Math.round(
          (data.userOnlyCount / count) * 100
        ).toFixed(0);
        this.userSource.user = Math.round((data.count1 / count) * 100).toFixed(
          0
        );
        this.userSource.agent = Math.round((data.count3 / count) * 100).toFixed(
          0
        );
        data.count3 / count;

        this.user_num = count;
      });
    },

    // 实时换电
    getRealExchangeRecord() {
      fetchUserEventList({
        pageIndex: 1,
        pageSize: 40
      }).then((res) => {
        this.realTimeData = res.data.data.records || [];
        this.realTimeData.map((item) => {
          item.realName = item.realName.replace(/(.).(.?)/, "$1*$2");
          item.startTime = moment(item.startTime).format("YYYY-MM-DD HH:mm:ss");
        });
        let len = this.realTimeData.length;
        let maxLen = 40;
        if (len < maxLen) {
          for (let i = 0; maxLen - len > i; i++) {
            this.realTimeData.push(this.realTimeData[i % len]);
          }
        }
      });
    },

    getOrderRank() {
      this.rankList = [
        {
          value: 0,
          name: "宝安区",
          children: [
            {
              name: "雅迪朗下网点",
              value: 0,
            },
            {
              name: "雅迪潭头网点",
              value: 0,
            },
            {
              name: "雅迪东方网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪楼岗网点",
              value: 0,
            },
          ],
        },
        {
          value: 0,
          name: "福田区",
          children: [
            {
              name: "雅迪朗下网点",
              value: 0,
            },
            {
              name: "雅迪潭头网点",
              value: 0,
            },
            {
              name: "雅迪东方网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪楼岗网点",
              value: 0,
            },
          ],
        },
        {
          value: 0,
          name: "南山区",
          children: [
            {
              name: "雅迪朗下网点",
              value: 0,
            },
            {
              name: "雅迪潭头网点",
              value: 0,
            },
            {
              name: "雅迪东方网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪楼岗网点",
              value: 0,
            },
          ],
        },
        {
          value: 0,
          name: "光明区",
          children: [
            {
              name: "雅迪朗下网点",
              value: 0,
            },
            {
              name: "雅迪潭头网点",
              value: 0,
            },
            {
              name: "雅迪东方网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪楼岗网点",
              value: 0,
            },
          ],
        },
        {
          value: 0,
          name: "龙华区",
          children: [
            {
              name: "雅迪朗下网点",
              value: 0,
            },
            {
              name: "雅迪潭头网点",
              value: 0,
            },
            {
              name: "雅迪东方网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪溪头网点",
              value: 0,
            },
            {
              name: "雅迪楼岗网点",
              value: 0,
            },
          ],
        },
      ];
    },

    // 鼠标进入修改changeRank
    changeOrderRankIndex(index) {
      this.rankIndex = index;
    },

    // 设备地区统计
    getDeviceArea() {
      this.deviceData = [
        { value: 14, name: "宝安区" },
        { value: 24, name: "光明区" },
        { value: 11, name: "龙华区" },
        { value: 13, name: "罗湖区" },
        { value: 15, name: "盐田区" },
        { value: 19, name: "龙岗区" },
      ];

      this.$refs.deviceChart.updateCharts(this.deviceData);
    },

    // 订单数统计
    getOrderRecord(type) {
      this.orderActive = type;
      let startTime = moment(new Date())
        .subtract(type, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      let endTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      fetchUserOrder({
        startTime: startTime,
        endTime: endTime,
      }).then((res) => {
        let data = res.data.data;
        if (type === "90") {
          this.quarterTrader = data.moneyCount / 100;
          this.$refs.gaugeCharts.updateCharts({
            current: this.quarterTrader,
            target: this.quarterTarget,
          });
        }
        this.orderData[type] = {
          trade_num: data.moneyCount / 100,
          order_num: data.orderCount,
        };
      });
    },

    // 换电量统计
    getExchangeRecord(type) {
      this.exchangeActive = type;
      let request = statisticsExchangeRecord;
      let obj = this.parseDate(type);
      if (this.exchangeData[type].label.length === 0) {
        request({
          ...obj,
        }).then((res) => {
          let label = [];
          let value = [];
          let data = res.data.data;
          let list = [];
          if (type === "year") {
            list = data.changeCountMonths;
          } else {
            list = data.changeCountDays;
          }

          let tar = moment(new Date());
          let cur = moment(obj.startTime);

          list.map((item) => {
            if (tar >= cur) {
              label.push(cur.format("YYYY-MM-DD"));
              value.push(item);
              console.log(item, "item");
              if (type == "year") {
                cur.add(1, "M");
              } else {
                cur.add(1, "d");
              }
            }
          });

          this.exchangeData[type].label = label;
          this.exchangeData[type].value = value;
          this.$refs.lineChart.updateCharts(this.exchangeData[type]);
        });
      } else {
        this.$refs.lineChart.updateCharts(this.exchangeData[type]);
      }
    },

    parseDate(type) {
      let obj = {
        startTime: "",
        endTime: "",
      };
      obj.startTime = moment(new Date()).startOf(type).format(this.dateFormat);
      obj.endTime = moment(new Date()).endOf(type).format(this.dateFormat);
      return obj;
    },

    // 数据统计
    getAllStatistics() {
      statisticsAll({ operationId: -1 }).then((res) => {
        this.device_num = res.data.data.batteryCount;
        this.changeCount = res.data.data.changeCount;
        this.station_num = res.data.data.stationCount;
        this.device_user_num = res.data.data.outBatteryCount;
      });
    },

    // 新增用户数据统计
    getUserStatistics() {
      let type = "month";
      let obj = this.parseDate(type);

      newUserDay({
        ...obj,
      }).then((res) => {
        let label = [];
        let value = [];
        let add = 0;

        let tar = moment(new Date());
        let cur = moment(obj.startTime);
        let list = res.data.data;
        let count = 0;
        list.map((item) => {
          count += item;
          if (tar >= cur) {
            label.push(cur.format("YYYY-MM-DD"));
            value.push(item);
            cur.add(1, "d");
          }
        });

        this.mouth_add = count;
        this.user_add = add;
        this.userData[type].label = label;
        this.userData[type].value = value;
        this.$refs.userCharts.updateCharts(this.userData[type]);
      });
    },

    setFont() {
      // 因为要定义变量可能和别的变量相互冲突，污染，所有用自调用函数
      var html = document.documentElement; // 获取html
      // 获取宽度
      var width = html.clientWidth;

      // 判断
      if (width < 1024) width = 1024;
      if (width > 1920) width = 1920;
      // 设置html的基准值
      var fontSize = width / 80 + "px";
      // 设置给html
      html.style.fontSize = fontSize;
    },
  },
  mounted() {
    this.getAllStatistics();
    this.getExchangeRecord("year");
    this.getUserStatistics();
    this.getRealExchangeRecord();
    this.getDeviceArea();
    this.getOrderRank();
    this.getOrderRecord("90");
    this.getOrderRecord("1");
    this.getUserSource();

    this.getRank();
  },

  created() {
    this.setFont();
  },
};
</script>

<style scoped>
@import "./css/index.css";
.viewport {
  background-color: #101129 !important;
}
</style>

