import { per } from '@/const/permission';
export default function (IGNORE) {
    return {
        title: '租车管理',
        name: 'bicycle',
        icon: 'icon-agent',
        children: [{
            title: '车辆电池记录',
            path: '/bicycle/battery',
            name: 'bicycleBattery',
            component: 'bicycle/battery',
            permission: per.SHOW_AGENT_LIST,
            isMenu: true
        },{
            title: '租车记录',
            path: '/bicycle/order',
            name: 'bicycleOrder',
            component: 'bicycle/order',
            permission: per.SHOW_AGENT_LIST,
            isMenu: true
        }, {
            title: '电动车列表',
            path: '/bicycle/page',
            name: 'bicyclePage',
            component: 'bicycle/page',
            permission: per.SHOW_AGENT_LIST,
            isMenu: true
        }, {
            title: '代理商管理',
            path: '/bicycle/agent/list',
            name: 'bicycleAgentList',
            component: 'bicycle/agent-list',
            permission: per.SHOW_AGENT_LIST,
            isMenu: true
        }, {
            title: '租车账号管理',
            path: '/bicycle/account',
            name: 'bicycleAccount',
            component: 'bicycle/account',
            permission: per.SHOW_AGENT_LIST,
            isMenu: true
        }]
    }
}
