<template>
    <div :class="this.cls" class="box">
        <h6 class="title">
            <el-divider content-position="left">{{title}}</el-divider>
        </h6>
        <div class="box-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['title', 'cls'],
        data() {
            return {};
        }
    }
</script>

<style lang="less" scoped>
    .box {
        position: relative;
        padding: 10px 10px 50px 10px;
        background: #fff;
        border-radius: 5px;

        .box-content {
            padding: 0 20px;
        }

        .title {
            .el-divider__text {
                background-color: #73D4F6;
                padding: 5px 34px 5px 14px;
                vertical-align: middle;
                font-size: 14px;
                color: #fff;
                margin-left: -40px;
                z-index: 100;
                display: table-cell;
                min-width: 105px;
                text-align: center;

                &:after {
                    content: '';
                    position: absolute;
                    top: -1px;
                    right: 0;
                    height: 111%;
                    width: 20px;
                    background: #fff;
                }
            }

            .el-divider.el-divider--horizontal {
                display: table;
                background: transparent;

                &:before {
                    display: none;
                }

                &:after {
                    content: '';
                    color: rgba(80, 80, 80, 1);
                    border-top: 3px solid rgba(214, 225, 236, 1);
                    border-radius: 3px;
                    height: 5px;
                    right: 0;
                    width: 100%;
                    display: table-cell;
                }
            }
        }
    }

    .box-login-line { //登录框
        .box-content {
            padding: 0;
        }

        .line {
            position: absolute;
            right: 20px;
            left: 60px;
            top: 30px;
        }
    }
</style>
