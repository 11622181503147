import { getStore, setStore, clearStore } from '@/common/store';
import { fetchStationListAll } from '@/api/station';
import store from '../index';

const station = {
    state: {
        stationList: getStore({
            name: 'stationList'
        }) || { list: [], map: {}, agentMap: {} }
    },
    actions: {
        fetchStationAll({ commit, state, dispatch }, params) {
            return new Promise((solve, reject) => {
                fetchStationListAll().then(res => {
                    commit('SET_STATION_LIST', res.data.data);
                    solve();
                })
            })

        },
    },
    mutations: {
        SET_STATION_LIST(state, stationList) {
            state.stationList.invalid = false;
            state.stationList.list = stationList;
            state.stationList.map = {};
            state.stationList.agentMap = {};
            stationList.map((item) => {
                state.stationList.map[item.id] = item.stationName;
                if (state.stationList.agentMap[item.operationId]) {
                    state.stationList.agentMap[item.operationId].push(item);
                } else {
                    state.stationList.agentMap[item.operationId] = [item];
                }
            });

            setStore({
                name: 'stationList',
                content: state.stationList,
                type: 'session'
            })
        },
    }
}

export default station;
