<template>
    <el-dialog
            title="图片预览"
            :close-on-click-modal="isClickModalClose"
            :visible.sync="isShow"
            :width="defaultWidth"
            @close="closeModal"
    >
        <div class="text-c">
            <img :src="url" class="img" alt="">
        </div>

        <div slot="footer">

            <el-button @click="closeModal" type="primary">
                关闭
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import modalMixin from '@/mixins/modalMixin';

    export default {
        mixins: [modalMixin],
        props: ['url'],
        data() {
            return {};
        },

    }
</script>

<style lang="less" scoped>
    .img {
        max-width: 100%;
        max-height: initial;
    }
</style>
