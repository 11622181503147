import {per} from '@/const/permission';

export default function (IGNORE) {
    return {
        title: '系统设置',
        name: 'account',
        icon: 'icon-user',
        children: [{
            title: '系统账号',
            path: '/system/user',
            name: 'system-user',
            component: 'system/user',
            permission: per.SHOW_SYS_USER,
            isMenu: true
        },{
            title: '系统角色',
            path: '/system/role',
            name: 'system-role',
            component: 'system/role',
            permission: per.SHOW_ROLE_LIST,
            isMenu: true
        },{
            title: '权限资源',
            path: '/system/permission',
            name: 'system-permission',
            component: 'system/permission',
            permission: per.SHOW_PERMISSION_TREE,
            isMenu: true
        }, {
            title: '员工管理',
            path: '/agent/account',
            name: 'agent-account',
            component: 'agent/account',
            permission: per.SHOW_AGENT_EMPLOYEE,
            isMenu: false
        }]
    }
}
