import {createStore } from 'vuex'
import user from './modules/user'
import agent from './modules/agent'
import station from './modules/station'
import common from './modules/common'
import page from './modules/page'
import db from './modules/db'
import getters from './getters'
import zzAgent from './modules/zzAgent';
import bicycleAgent from './modules/bicycle';

const state = {
  sidebarOpened: localStorage.getItem('sidebarStatus') ? !!+localStorage.getItem('sidebarStatus') : true
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebarOpened = !state.sidebarOpened;
    if (state.sidebarOpened) {
      localStorage.setItem('sidebarStatus', 1);
    } else {
      localStorage.setItem('sidebarStatus', 0);
    }
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR');
  }
}

const store = createStore({
  state,
  mutations,
  actions,
  modules: {
    user,
    agent,
    zzAgent,
    bicycleAgent,
    station,
    common,
    page,
    db
  },
  getters
})

export default store
