import request from '@/common/axios';

// 代理商列表
export function fetchAgentList(data) {
  return request({
    url: '/web/admin/operation/operationPage',
    method: 'POST',
    data: data
  });
}

// 代理商列表
export function fetchAgentListAll(data) {
  return request({
    url: '/web/admin/operation/getOperationList',
    method: 'GET',
  });
}

// 添加代理商
export function addAgent(data) {
  return request({
    url: '/web/admin/operation/addOperation',
    method: 'POST',
    data: data
  });
}


export function delAgent(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 1102,
      oReqData: {
        ...data
      }
    }
  });
}

// 修改代理商
export function updateAgent(data) {
  return request({
    url: '/web/admin/operation/updateOperation',
    method: 'POST',
    data: data
  });
}

// 代理商充值记录
export function agentChargeRecordPage(data) {
  return request({
    url: '/web/admin/operationChargeRecord/operationChargeRecordPage',
    method: 'POST',
    data: data
  });
}


// 代理商用户资产明细
export function agentAssetDetail(data) {
  return request({
    url: '/web/admin/operationDaysRecord/operationDaysRecordPage',
    method: 'POST',
    data: data
  });
}

// 代理商资产
export function agentAsset(data) {
  return request({
    url: '/web/admin/operationWallet/operationWalletPage',
    method: 'POST',
    data: data
  });
}

// 代理商充值申请
export function addAgentCharge(data) {
  return request({
    url: '/web/admin/operationChargeRecord/addOperationChargeRecord',
    method: 'POST',
    data: data
  });
}

// 代理商充值申请审核
export function addAgentChargeApply(data) {
  return request({
    url: '/web/admin/operationChargeRecord/updateOperationChargeRecord',
    method: 'POST',
    data: data
  });
}

// 代理商充值申请审核
export function agentAddUser(data) {
  return request({
    url: '/web/admin/user/addOperationUser',
    method: 'POST',
    data: data
  });
}

// 代理商用户列表
export function agentUserList(data) {
  return request({
    url: '/web/admin/user/operationUserPage',
    method: 'POST',
    data: data
  });
}

// 用户续费记录
export function agentUserCharList(data) {
  return request({
    url: '/web/admin/userRechargeDaysRecord/userRechargeDaysRecordPage',
    method: 'POST',
    data: data
  });
}


// 导出资产明细
export function exportAgentAssetDetail(data) {
  return request({
    url: '/web/admin/operationDaysRecord/operationDaysRecordDownload',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: data
  });
}