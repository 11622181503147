import {getStore, setStore} from '@/common/store'

const common = {
  state: {
    examInfo: getStore({
      name: 'examInfo'
    }) || '',

    regionTree:{
      invalid: true,
      data: [],
    }
  },

  getters: {},
  mutations: {

  },
  actions: {

  }
}
export default common
