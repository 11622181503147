import { getStore, setStore, clearStore } from '@/common/store'
import { login, doRefreshToken, fetchUserPermission } from '@/api/user'
import { validatenull } from "@/common/validate";
import { menuList, IGNORE } from '@/router/nav';
import { deepClone } from "@/common/util";

function initMenu(permission) {
    let children = [];
    let menu = deepClone(menuList);
    menu && menu.map(item => {
        children = [];
        item.children && item.children.map((child, index) => {
            if (child.isMenu && (child.permission == IGNORE || permission[child.permission])) {
                children.push(child);
            }
        })
        item.children = children;
    })
    menu = menu.filter(item => {
        return item.children.length !== 0;
    })
    return menu;
}

const user = {
    state: {
        userInfo: getStore({
            name: 'userInfo'
        }) || {},
        roles: '',
        menu: getStore({
            name: 'menu'
        }) || [],
        menuAll: [],
        userType: getStore({
            name: 'userType',
        }),
    },
    actions: {
        // 更新token
        handleRefreshToken({ state, commit, dispatch }) {
            let refreshToken = state.userInfo.refreshToken;
            let token = state.userInfo.token;
            let data = {
                token,
                refreshToken,
            };
            return new Promise((reslove, reject) => {
                doRefreshToken(data).then((res) => {
                    let info = Object.assign(state.userInfo, { token: res.data.data.newtoken });
                    commit('SET_USER_INFO', info);
                    console.log('doRefreshToken,reslove');
                    reslove(res);
                }).catch(error => {
                    console.log('doRefreshToken,error');
                    reject(error);
                })
            })
        },
        //登录
        handleLogin({ commit, dispatch }, params) {
            return new Promise((resolve, reject) => {
                login(params).then(res => {
                    let data = res.data.data;
                    let userInfo = {
                        token: data,
                    }
                    // TODO 获取用户权限
                    commit('SET_USER_INFO', userInfo);
                    dispatch("getUserPermission", data.id).then(res => {
                        resolve();
                    })
                }).catch(error => {
                    reject(error)
                })
            })
        },

        //获取用户权限
        getUserPermission({ commit, state }, params) {
            return fetchUserPermission().then(res => {
                let info = state.userInfo;
                let obj = {};
                res.data.data.map(item => {
                    obj[item] = true;
                })
                info.permission = obj;
                state.menu = initMenu(obj);
                commit('SET_USER_INFO', info);
            })
        },

        // 登出
        LogOut({ commit, dispatch }) {
            dispatch('clearData');
            setTimeout(() => {
                location.reload();
            }, 100)
        },

        clearData({ commit, dispatch }) {
            clearStore({ type: 'session' });
        },
    },
    mutations: {
        SET_USER_TYPE(state, userType) {
            state.userType = userType;
            setStore({
                name: 'userType',
                content: userType,
                type: 'session'
            })
        },
        SET_USER_INFO(state, userInfo) {
            state.userInfo = Object.assign({}, state.userInfo, userInfo);
            setStore({
                name: 'userInfo',
                content: userInfo,
                type: 'session'
            })
        },
    }
}

export default user
