<template>
  <el-container>
    <el-header height="90px">
      <el-row type="flex" align="middle" class="web-wrapper">
        <el-col :span="2"
          ><img src="@/assets/image/logo.png" width="60" alt=""
        /></el-col>
        <el-col :span="20"
          ><h1 class="title">{{ $store.getters.getSetting.title }}</h1></el-col
        >
      </el-row>
    </el-header>

    <el-main>
      <div class="web-wrapper">
        <router-view v-slot="{ Component }">
          <transition name="fadeTranslateY" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </div>
    </el-main>

    <el-footer height="140px">
      <div class="web-wrapper"></div>
    </el-footer>
  </el-container>
</template>

<script>
import { exportAnchorFile } from "@/common/util";

export default {
  name: "WebLayout",
  computed: {
    keyPath() {
      return this.$route.fullPath;
    },
    methods: {},
    mounted() {
      document.title = this.$store.getters.getSetting.title;
    },
  },
};
</script>

<style lang="less" scoped>
.web-wrapper {
  width: 1100px;
  height: 100%;
  margin: 0 auto;
}

.el-header {
  background-color: #deecfe;

  .title {
    font-size: 24px;
    color: #0098e6;
    font-weight: bold;
    line-height: 90px;
  }

  .r-link {
    margin-top: 50px;
    color: #e27718;
    cursor: pointer;

    &:hover {
      color: #e27718cf;
    }
  }
}

.el-footer {
  margin: 24px 50px;
  font-size: 12px;

  .copyright {
    margin-bottom: 8px;
    text-align: center;
  }

  .content {
    width: 422px;
    line-height: 28px;
    margin: 0 auto;
    position: relative;

    a:hover {
      text-decoration: underline;
    }

    .qq-btn {
      vertical-align: -5px;
      margin-left: 3px;
    }

    .qq-code {
      line-height: 15px;
      position: absolute;
      right: 0;
      top: 7px;
      text-align: center;
    }
  }
}

.el-main {
  min-height: 600px;
  padding: 2% 0;
  background-image: url("../../assets/image/forget-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
