import { getStore, setStore, clearStore } from '@/common/store';
import { fetchZZAgentList } from '@/api/zzAgent';

const zzAgent = {
    state: {
        list: getStore({
            name: 'list'
        }) || [] ,
    },
    actions: {
        fetchZZAgentListAll({ commit, state, dispatch }, params) {
            return new Promise((solve, reject) => {
                fetchZZAgentList().then(res => {
                    commit('SET_LIST', res.data.data);
                    solve();
                })
            })
        },
    },
    mutations: {
        SET_LIST(state, agentList) {
            state.list = agentList;
            setStore({
                name: 'zzAgentList',
                content: state.list,
                type: 'session'
            })
        },
    }
}

export default zzAgent;
