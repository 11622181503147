<template>
  <v-chart :option="option" ref="charts" />
</template>

<script>
import { use, graphic } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import { ref, defineComponent } from "vue";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default defineComponent({
  name: "barCharts",
  props: ["data"],
  components: {
    VChart,
  },

  setup() {
    let item = {
      name: "",
      value: 1200,
      // 柱子颜色
      itemStyle: {
        color: "#254065",
      },
      // 鼠标经过柱子颜色
      emphasis: {
        itemStyle: {
          color: "#254065",
        },
      },
      // 工具提示隐藏
      tooltip: {
        extraCssText: "opacity:0",
      },
    };
    const option = ref({
      // 工具提示
      tooltip: {
        trigger: "axis",
      },
      // 图表边界控制
      grid: {
        // 距离 上右下左 的距离
        left: "0",
        right: "3%",
        bottom: "3%",
        top: "5%",
        // 大小是否包含文本
        containLabel: true,
        //显示边框
        show: true,
        //边框颜色
        borderColor: "rgba(0, 240, 255, 0.3)",
      },
      // 控制x轴
      xAxis: [
        {
          // 使用类目，必须有data属性
          // 使用 data 中的数据设为刻度文字
          type: "category",
          data: [],
          // 刻度设置
          axisTick: {
            // true意思：图形在刻度中间
            // false意思：图形在刻度之间
            alignWithLabel: false,
            show: false,
          },
          //文字
          axisLabel: {
            color: "#4c9bfd",
          },
        },
      ],
      // 控制y轴
      yAxis: [
        {
          // 使用数据的值设为刻度文字
          type: "value",
          axisTick: {
            // true意思：图形在刻度中间
            // false意思：图形在刻度之间
            alignWithLabel: false,
            show: false,
          },
          //文字
          axisLabel: { 
            color: "#4c9bfd",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(0, 240, 255, 0.3)",
            },
          },
        },
      ],
      // 控制x轴
      series: [
        {
          // series配置
          // 颜色
          itemStyle: {
            // 提供的工具函数生成渐变颜色
            color: new graphic.LinearGradient(
              // (x1,y2) 点到点 (x2,y2) 之间进行渐变
              0,
              0,
              0,
              1,
              [
                { offset: 0, color: "#00fffb" }, // 0 起始颜色
                { offset: 1, color: "#0061ce" }, // 1 结束颜色
              ]
            ),
          },
          // 图表数据名称
          name: "用户统计",
          // 图表类型
          type: "line",
          smooth: true,
          // 柱子宽度
          barWidth: "60%",
          // 数据
          data: [],
        },
      ],
    });
    const charts = ref(null);
    const updateCharts = (data) => {
      let newOption = option.value;
      newOption.xAxis[0].data = data.label;
      newOption.series[0].data = data.value;
      charts.value.setOption(newOption);
    };

    return { option, updateCharts, charts };
  },
});
</script>

<style scoped>
.chart {
  width: 25.5rem;
}
</style>