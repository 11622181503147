<!-- 批量上传头像 1.按照准考证 2.按照身份证 -->
<template>
    <div>
        <el-dialog
                :visible.sync="isShow"
                :close-on-click-modal="isClickModalClose"
                :width="defaultWidth"
                :title="`批量上传头像`"
        >
            <el-form :ref="formName" :model="fileData" :rules="ruleValidate" label-width="100px">

                <el-form-item label="上传头像：" prop="files">
                    <el-upload
                            multiple
                            :before-upload="handleBatchUpload"
                            action="/"
                            accept="image/*"
                    >
                        <el-button type="default" icon="md-image">头像导入</el-button>
                        <div style="line-height: 24px;">
                            <p>已选中 <span class="color-danger">{{fileData.files.length}}</span> 张头像</p>
                            <p class="color-danger">单个文件最大20M</p>
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="default" @click="closeModal">取消</el-button>
                <el-button type="primary" @click="submit">确定</el-button>
            </div>
        </el-dialog>

        <UploadProgressModal :ref="MODAL_KEY.UPLOAD_PROGRESS_MODAL" :uploadPercent="uploadPercent" @complete="complete"></UploadProgressModal>
    </div>
</template>

<script>
    import modalMixin from '@/mixins/modalMixin';
    import request from '@/common/axios';
    import UploadProgressModal from './uploadProgressModal';

    export default {
        mixins: [modalMixin],
        props: ['modalType', 'parseParams', 'interfaceObj','test'],
        components: {UploadProgressModal},
        data() {
            return {
                formName: 'form',
                fileMaxLength: 20 * 1024 * 1024,
                uploadPercent: -1,
                MODAL_KEY: {
                    UPLOAD_PROGRESS_MODAL: 'UPLOAD_PROGRESS_MODAL',
                },
                fileData: {
                    examId: this.$store.state.common.examInfo.examId,
                    type: 2,
                    files: [],
                },
                sizeMsg: [],
                ruleValidate: {
                    type: {
                        required: true,
                        message: '请选择头像上传规则'
                    }
                }
            };
        },
        methods: {
            handleBatchUpload(files) {
                if (files.size < this.fileMaxLength) {
                    this.fileData.files.push(files);
                } else {
                    this.sizeMsg.push(`<p>头像：${files.name} 大于20M不能上传，是否继续上传！</p>`);
                }
                return false;
            },

            closeModal() {
                this.fileData.files = [];
                this.sizeMsg = [];
                this.isShow = false;
            },

            showModal() {
                this.fileData.files = [];
                this.isShow = true;
            },

            submit() {
                if (this.sizeMsg.length > 0) {
                    this.$confirm('有文件大于20M将会被过滤，是否继续上传', '提示', {type: 'warning'}).then(res => {
                        let filesList = this.splitFiles(this.fileData.files, 20);
                        this.$emit('batSubmit', filesList, this.fileData);
                    }).catch(() => {
                        this.fileData.files = [];
                        this.sizeMsg = [];
                    });

                    return;
                }
                if (this.fileData.files.length === 0) {
                    this.$message({
                        message: '请选择头像',
                        type: 'warning'
                    });
                    return;
                }
                let filesList = this.splitFiles(this.fileData.files, 20);
                this.batSubmit(filesList, this.fileData);
            },

            batSubmit(filesList, fileData) {
                let result = [];
                let count = 0;
                this.$refs[this.MODAL_KEY.UPLOAD_PROGRESS_MODAL].showModal();
                this.uploadPercent = 0;
                filesList.map(item => {
                    let param = this.parseParams(item, fileData);
                    result.push(() => {
                            return this.interfaceObj(param).then(res => { //这里处理进度条
                                this.uploadPercent = parseFloat((this.uploadPercent + ((1 / filesList.length) * 100)).toFixed(2));
                                count += res.data.data;
                            })
                        }
                    );
                });

                request.all(result.map(item => {
                    return item()
                })).then(res => {
                    this.uploadPercent = 100;
                })
            },

            complete() {
                this.$refs[this.MODAL_KEY.UPLOAD_PROGRESS_MODAL].closeModal();
                this.uploadPercent = 0;
                this.$emit('complete');
            },


            splitFiles(files, size) {
                let len = files.length;
                let index = 0;
                let filesList = [];
                while (len > index) {
                    filesList.push(files.slice(index, index + size));
                    index += size;
                }
                return filesList;
            },
        },
    }
</script>
