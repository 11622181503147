<template>
  <v-chart :option="option" ref="charts" />
</template>

<script>
import { use} from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LinesChart,EffectScatterChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GeoComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import { ref, defineComponent } from "vue";
import './shenZhen';


use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GeoComponent,
  LinesChart,
  EffectScatterChart,
]);

export default defineComponent({
  name: "mainCharts",
  components: {
    VChart,
  },

  setup() {
    var geoCoordMap = {
      南山区: [113.92923, 22.531221],
      罗湖区: [114.123885, 22.555341],
      福田区: [114.05096, 22.541009],
      龙华区: [114.044346, 22.691963],
      宝安区: [113.828671, 22.754741],
      龙岗区: [114.251372, 22.721511],
      坪山区: [114.338441, 22.69423],
      光明区: [113.935895, 22.748816],
      盐田区: [114.235366, 22.555069],
      深圳基地: [113.933499,22.579106],
    };

    var BJData = [
      [
        {
          name: "深圳基地",
        },
        {
          name: "宝安区",
          value: 515,
          volume: 84515.22,
        },
      ],
      [
        {
          name: "深圳基地",
        },
        {
          name: "龙华区",
          value: 453,
          volume: 68051.51,
        },
      ],
      [
        {
          name: "深圳基地",
        },
        {
          name: "深圳基地",
          value: 453,
          volume: 48051.51,
        },
      ],
      [
        {
          name: "深圳基地",
        },
        {
          name: "福田区",
          value: 231,
          volume: 35114.22,
        },
      ],
      [
        {
          name: "深圳基地",
        },
        {
          name: "南山区",
          value: 651,
          volume: 32155.01,
        },
      ],
      [
        {
          name: "深圳基地",
        },
        {
          name: "光明区",
          value: 593,
          volume: 72155.01,
        },
      ],
      [
        {
          name: "深圳基地",
        },
        {
          name: "坪山区",
          value: 212,
          volume: 32155.01,
        },
      ],
      [
        {
          name: "深圳基地",
        },
        {
          name: "龙岗区",
          value: 112,
          volume: 52155.01,
        },
      ],
      [
        {
          name: "深圳基地",
        },
        {
          name: "盐田区",
          value: 322,
          volume: 32155.01,
        },
      ], [
        {
          name: "深圳基地",
        },
        {
          name: "罗湖区",
          value: 432,
          volume: 3215,
        },
      ],
    ];

    var planePath =
      "path://M.6,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705";

    var convertData = function (data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var dataItem = data[i];
        var fromCoord = geoCoordMap[dataItem[0].name];
        var toCoord = geoCoordMap[dataItem[1].name];
        if (fromCoord && toCoord) {
          res.push([
            {
              coord: fromCoord,
            },
            {
              coord: toCoord,
            },
            {
              name: dataItem[1].name,
            },
            {
              value: dataItem[1].value,
            },
            {
              volume: dataItem[1].volume,
            },
          ]);
        }
      }
      return res;
    };

    var color = ["#3ed4ff", "#ffa022", "#a6c84c"];
    var series = [];
    [["罗湖区", BJData]].forEach(function (item, i) {
      series.push(
        {
          name: "设备出货",
          type: "lines",
          zlevel: 1,
          effect: {
            show: true,
            period: 6,
            trailLength: 0.7,
            color: "#fff",
            symbolSize: 3,
          },
          lineStyle: {
            normal: {
              color: color[i],
              width: 0,
              curveness: 0.2,
            },
          },
          data: convertData(item[1]),
        },
        {
          name: "设备出货",
          type: "lines",
          zlevel: 2,
          effect: {
            show: true,
            period: 6,
            trailLength: 0,
            symbol: planePath,
            symbolSize: 15,
          },
          lineStyle: {
            normal: {
              color: color[i],
              width: 1,
              opacity: 0.4,
              curveness: 0.2,
            },
          },
          data: convertData(item[1]),
          tooltip: {
            position: (point, params, dom, rect, size) => {
              return [point[0] + 15, point[1] + 15];
            },
            formatter: (params, ticket, callback) => {
              var data = convertData(item[1]);
              return (
                "设备安装量<br/>" +
                data[params.dataIndex][2].name +
                "：" +
                parseInt(data[params.dataIndex][3].value / 1.5)
              );
            },
          },
        },
        {
          name: "换电量",
          type: "effectScatter",
          coordinateSystem: "geo",
          zlevel: 2,
          rippleEffect: {
            brushType: "stroke",
          },
          label: {
            normal: {
              show: true,
              position: "right",
              formatter: "{b}",
            },
          },
          symbolSize: function (val) {
            return val[3] / 8854;
          },
          itemStyle: {
            normal: {
              color: color[i],
            },
          },
          tooltip: {
            position: "right",
            formatter: (params, ticket, callback) => {
              return (
                "交易订单量<br/>" +
                params.data.name +
                "：" +
                params.data.value[3]
              );
            },
          },
          data: item[1].map(function (dataItem) {
            return {
              name: dataItem[1].name,
              value: geoCoordMap[dataItem[1].name].concat(
                [dataItem[1].value],
                [dataItem[1].volume]
              ),
            };
          }),
        }
      );
    });

    const option = ref({
      backgroundColor: "#080a20",
      title: {
        left: "left",
        textStyle: {
          color: "#fff",
        },
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        top: "bottom",
        left: "right",
        data: ["北京 Top10", "上海 Top10", "广州 Top10"],
        textStyle: {
          color: "#fff",
        },
        selectedMode: "single",
      },
      geo: {
        map: "shenzhen",
        zoom: 1,
        label: {
          emphasis: {
            show: false,
          },
        },
        roam: true,
        center: [113.934334, 22.580128],
        nameMap: {
          China: "深圳",
        },
        itemStyle: {
          normal: {
            areaColor: "#142957",
            borderColor: "#0692a4",
          },
          emphasis: {
            areaColor: "#0b1c2d",
          },
        },
      },
      series: series,
    });

    return { option };
  },
});
</script>

<style scoped>
.chart {
  width: 25.5rem;
}
</style>