<template>
    <el-upload
            :action="baseUrl+action"
            :on-success="handleAvatarSuccess"
            :data="data"
            :file-list="fileList"
            :on-remove="removeList"
            :disabled="disabled"
            :headers="{'Authorization':'Bearer '+ token}"
            :before-upload="beforeAvatarUpload">
        <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
</template>

<script>
import { baseUrl } from "@/config/env";
    export default {
        props: ['action', 'data','disabled'],
        emits: ['success','remove'],
        data() {
            let token = this.$store.getters.token
            return {
                baseUrl: baseUrl,
                token: token,
                fileList: [],
            };
        },
        methods: {
            removeList(e){
                  this.$emit('remove', e);
            },
            handleAvatarSuccess(res, file) {
                if (res.iRspResult === 0) {
                    this.$emit('success', res);
                    this.fileList = [file]
                } else {
                    this.$message.error('上传异常，请稍后再试！');
                }
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isLt2M) {
                    this.$message({
                        message: '上传文件大小不能超过 2MB!',
                        type: 'error'
                    });
                }
                return isLt2M;
            },
        },
    }
</script>

<style lang="less" scoped>
    .avatar-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .el-upload:hover {
            border-color: #409EFF;
        }

        .avatar {
            width: 108px;
            height: 108px;
            display: block;
        }

        .el-icon-plus.avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 108px;
            height: 108px;
            line-height: 108px;
            text-align: center;
        }
    }

</style>
