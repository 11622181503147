<template>
  <el-upload
    class="avatar-uploader"
    :action="baseUrl  + action"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :data="data"
    :disabled="disabled"
    accept="image/*"
    :headers="{ token: token }"
    :before-upload="beforeAvatarUpload"
  >
    <img
      v-if="imageUrl || defaultUrl"
      :src="imageUrl || defaultUrl"
      class="avatar"
      @click="showPreview"
    />
    <img :src="defaultBg" alt="" v-else-if="defaultBg" style="height: 80px" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    <Preview ref="preview" :url="url"></Preview>
  </el-upload>
</template>

<script>
import Preview from "@/components/previewImg";
import { baseUrl } from "@/config/env";
export default {
  components: { Preview },
  emits: ['success'],
  props: ["action", "data", "defaultUrl", "disabled", "defaultBg", "width"],
  data() {
    let token = this.$store.getters.token;
    return {
      imageUrl: "",
      baseUrl: baseUrl,
      token: token,
      url: "",
    };
  },
  methods: {
    showPreview() {
      if (this.disabled) {
        //只有是disabled状态可以预览
        this.url = this.imageUrl || this.defaultUrl;
        this.$refs.preview.showModal();
      }
    },
    handleAvatarSuccess(res, file) {
      if (res.message==="OK") {
        this.$emit("success", res.data.path);
      } else {
        this.$message.error("上传异常，请稍后再试！");
      }
    },
    beforeAvatarUpload(file) {
      let format = ["image/jpg", "image/jpeg", "image/png"];

      const isImage = format.indexOf(file.type) > -1;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message({
          message: "上传头像图片只能是 JPG, PNG,JPEG 格式!",
          type: "error",
        });
      }
      if (!isLt2M) {
        this.$message({
          message: "上传头像图片大小不能超过 2MB!",
          type: "error",
        });
      }
      return isImage && isLt2M;
    },
  },
  watch: {
    defaultUrl(newValue) {
      this.imageUrl = newValue || "";
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  width: 108px;
  height: 108px;
  line-height: 108px;
  /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .el-upload:hover {
    border-color: #409eff;
  }

  .avatar {
    height: 100%;
  }

  .el-icon-plus.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
</style>
