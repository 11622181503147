import { per } from '@/const/permission';
export default function (IGNORE) {
    return {
        title: '数据看板',
        name: 'board',
        icon: 'icon-statistics',
        children: [
            {
                title: '数据看板',
                path: '/statistics',
                name: 'global-statistics',
                component: 'global/statistics',
                permission: per.SHOW_DATA_BOARD,
                isMenu: true,
                click: () => { window.open("/statistics") }
            },
        ],
        permission: per.DATA_BOARD,
    }
}
