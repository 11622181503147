<template>
  <v-chart :option="option" ref="charts" />
</template>

<script>
import { use, graphic ,setOption} from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import { ref, defineComponent, reactive, watch } from "vue";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default defineComponent({
  name: "LineCharts",
  props: ["data"],
  components: {
    VChart,
  },

  setup(props, context) {
    let item = {
      name: "",
      value: 1200,
      // 柱子颜色
      itemStyle: {
        color: "#254065",
      },
      // 鼠标经过柱子颜色
      emphasis: {
        itemStyle: {
          color: "#254065",
        },
      },
      // 工具提示隐藏
      tooltip: {
        extraCssText: "opacity:0",
      },
    };
    const option = ref({
      //鼠标提示工具
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        // 类目类型
        type: "category",
        // x轴刻度文字
        data: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        axisTick: {
          show: false, //去除刻度线
        },
        axisLabel: {
          color: "#4c9bfd", //文本颜色
        },
        axisLine: {
          show: false, //去除轴线
        },
        boundaryGap: false, //去除轴内间距
      },
      yAxis: {
        // 数据作为刻度文字
        type: "value",
        axisTick: {
          show: false, //去除刻度线
        },
        axisLabel: {
          color: "#4c9bfd", //文本颜色
        },
        axisLine: {
          show: false, //去除轴线
        },
        boundaryGap: false, //去除轴内间距
      },
      //图例组件
      legend: {
        textStyle: {
          color: "#4c9bfd", // 图例文字颜色
        },
        right: "10%", //距离右边10%
      },
      // 设置网格样式
      grid: {
        show: true, // 显示边框
        top: "20%",
        left: "3%",
        right: "4%",
        bottom: "3%",
        borderColor: "#012f4a", // 边框颜色
        containLabel: true, // 包含刻度文字在内
      },
      series: [{
          name: "换电次数",
          data: [],
          type: "line",
          smooth: true,
          itemStyle: {
            color: "#ed3f35", // 线颜色
          },
        },
      ],
    });
    const charts = ref(null);
    const updateCharts = (data) => {
      let newOption = option.value;
      newOption.xAxis.data = data.label;
      newOption.series[0].data = data.value;
      charts.value.setOption(newOption);
    };
    return { option,updateCharts , charts};
  },
});
</script>

<style scoped>
.chart {
  width: 25.5rem;
}
</style>