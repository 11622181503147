
const getters = {
  token: (state) => {
    return state.user.userInfo.token
  },
  refreshToken: (state) => state.user.refreshToken,
  roles: (state) => {
    return state.user.userInfo.role;
  },
  permissions: (state) => {
    return state.user.userInfo.permission || {};
  },
  userInfo: (state) => {
    return state.user.userInfo;
  },

  getSetting: (state) => {
    let setting = {
      title: '充换电管理系统'
    };
   

    return setting;
  },
};
export default getters;
