<template>
  <el-row :gutter="20">
    <el-col :span="9">
      <el-button-group v-if="type === 'year'">
        <el-button @click="changeDate('year', '1')">去年</el-button>
        <el-button @click="changeDate('year', '0')">今年</el-button>
      </el-button-group>

      <el-button-group v-else>
        <el-button @click="changeDate('day', '0')">今天</el-button>
        <el-button @click="changeDate('day', '1')">昨天</el-button>
        <el-button @click="changeDate('week', '1')">上周</el-button>
        <el-button @click="changeDate('month', '0')">本月</el-button>
      </el-button-group>
    </el-col>

    <el-col :span="15">
      <el-date-picker
        v-model="curValue"
        type="daterange"
        range-separator="至"
        @change="change"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
      ></el-date-picker>
    </el-col>
  </el-row>
</template>

<script>
import { toRef, reactive, toRefs } from "vue";
import moment from "moment";

export default {
  props: ["modelValue", "type"],
  emits: ["change", "update:modelValue"],
  setup(props, context) {
    let value = toRef(props, "modelValue");

    let state = reactive({
      curValue: value.value,
    });

    const change = (e) => {
      let { emit } = context;

      context.emit("update:modelValue", e);
      context.emit("change", e);
    };

    const changeDate = (type, unit) => {
      let startTime = new moment()
        .startOf(type)
        .subtract(unit, type)
        .format("YYYY-MM-DD HH:mm:ss");
      let endTime = new moment()
        .endOf(type)
        .subtract(unit, type)
        .format("YYYY-MM-DD HH:mm:ss");

      let { emit } = context;
      state.curValue = [startTime, endTime];

      context.emit("update:modelValue", state.curValue);
      context.emit("change", state.curValue);
    };

    return {
      ...toRefs(state),
      change,
      changeDate,
    };
  },
};
</script>
