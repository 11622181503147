import { per } from '@/const/permission';

export default function (IGNORE) {
    return {
        title: '站长代付列表',
        name: 'zz-agent',
        icon: 'icon-user',
        children: [{
            title: '站长列表',
            path: '/zz/agent/list',
            name: 'zz-agent',
            component: 'zzAgent/list',
            isMenu: true,
            permission: per.SHOW_USER_LIST,
        }, {
            title: '站长账号列表',
            path: '/zz/agent/account',
            name: 'zz-agent-account',
            component: 'zzAgent/account',
            isMenu: true,
            permission: per.SHOW_USER_LIST,
        }, {
            title: '代付订单列表',
            path: '/zz/agent/order',
            name: 'zz-agent-order',
            component: 'zzAgent/order',
            isMenu: true,
            permission: per.SHOW_USER_LIST,
        }, {
            title: '代付用户列表',
            path: '/zz/agent/user',
            name: 'zz-agent-user',
            component: 'zzAgent/user',
            isMenu: true,
            permission: per.SHOW_USER_LIST,
        }, {
            title: '结算详情',
            path: '/zz/agent/settle/list',
            name: 'zz-agent-settle-list',
            component: 'zzAgent/settle-list',
            isMenu: true,
            permission: per.SHOW_USER_LIST,
        }
        ]
    }
}
