<template>
  <el-scrollbar wrap-class="sider-scrollbar">
    <el-menu
      unique-opened
      background-color="#304156"
      text-color="#bfcbd9"
      mode="vertical"
      :default-active="activeMenu"
      :collapse="isCollapse"
      :collapse-transition="false"
      :router="true"
    >
      <template
        class="menu-item"
        v-for="(item, index) in menuList"
        :key="item.path"
      >
        <el-sub-menu
          v-if="item.children.length > 1"
          :key="index"
          :index="item.name"
        >
          <template #title>
            <i class="iconfont" :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </template>

          <el-menu-item
            :key="i"
            :index="child.name"
            v-for="(child, i) in item.children"
            :route="{ path: child.click ? '' : child.path }"
            @click="child.click"
          >
            <span>{{ child.title }}</span>
          </el-menu-item>
        </el-sub-menu>

        <el-menu-item
          v-else
          :index="child.name"
          v-for="(child, i) in item.children"
          :route="{ path: child.click ? '' : child.path }"
          @click="child.click"
          :key="i + child.name"
        >
          <i class="iconfont" :class="item.icon"></i>
          <template #title>{{ child.title }}</template>
        </el-menu-item>
      </template>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { menuList, IGNORE } from "@/router/nav";

export default {
  name: "SiderMenu",
  data() {
    let menuListTemp = this.deepClone(menuList);
    menuListTemp.map((item) => {
      item.children =
        item.children &&
        item.children.filter((item) => {
          return (
              item.isMenu &&
                 (item.permission === IGNORE ||
                this.$store.getters.permissions[item.permission])
            );
            
          return item.isMenu;
        });
    });
    console.log(menuListTemp, "menuList");
    return {
      menuList: menuListTemp,
    };
  },

  computed: {
    activeMenu() {
      const { name, meta } = this.$route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return name;
    },
    isCollapse() {
      return !this.$store.state.sidebarOpened;
    },
  },
  methods: {
    updateMenu() {
      let menuListTemp = this.deepClone(menuList);
      menuListTemp.map((item) => {
        item.children =
          item.children &&
          item.children.filter((item) => {
            return (
              item.isMenu &&
              (item.permission === IGNORE ||
                this.$store.getters.permissions[item.permission])
            );
          });
      });
      this.menuList = menuListTemp;
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          this.onlyOneChild = item;
          return true;
        }
      });
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-scrollbar {
  height: calc(100% - 70px);

  .el-menu {
    border-right: none;
  }

  .menu-item i {
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
  }
}
</style>
