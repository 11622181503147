import { reg, validatenull } from './validate'
import request from '@/common/axios'

// 表单序列化
export const serialize = data => {
    let list = []
    Object.keys(data).forEach(ele => {
        list.push(`${ele}=${data[ele]}`)
    })
    return list.join('&')
}

export const getObjType = obj => {
    var toString = Object.prototype.toString
    var map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    }
    if (obj instanceof Element) {
        return 'element'
    }
    return map[toString.call(obj)]
}

export const timestampToTime = (timestamp) => {
    if (datenull(timestamp)) {
        return
    }
    let data = new Date(parseInt(timestamp))
    let year = data.getFullYear()
    let month = data.getMonth() + 1
    let date = data.getDate()
    let hour = data.getHours()
    let minute = data.getMinutes()
    let second = data.getSeconds()
    return year + '-' + addZero(month) + '-' + addZero(date) + '   ' + addZero(hour) + ':' + addZero(minute) + ':' + addZero(second)
}

function addZero(value) {
    if (value < 10) {
        return '0' + value
    } else {
        return value
    }
}

//计算比例
export function calcRate(count, num, f) {
    let c = Number(count);
    let n = Number(num);
    if (isNaN(c) || isNaN(n) || n === 0 || count === 0) {
        return '0%'
    }
    return ((n / c) * 100).toFixed(f || 2) + '%';
}

/**
 * 对象深拷贝
 */
export const deepClone = data => {
    var type = getObjType(data)
    var obj
    if (type === 'array') {
        obj = []
    } else if (type === 'object') {
        obj = {}
    } else {
        // 不再具有下一层次
        return data
    }
    if (type === 'array') {
        for (var i = 0, len = data.length; i < len; i++) {
            obj.push(deepClone(data[i]))
        }
    } else if (type === 'object') {
        for (var key in data) {
            obj[key] = deepClone(data[key])
        }
    }
    return obj
}

export const formatDay = (sec) => {
    if(sec<0){
        return '已过期'
    }
    let days = Math.floor(sec / 60 / 60 / 24);
    let hours = Math.floor((sec - (days * 60 * 60 * 24)) / 60 / 60);
    let minutes = Math.floor((sec - (days * 60 * 60 * 24) - (hours * 60 * 60)) / 60);
    let seconds = Math.floor((sec - (days * 60 * 60 * 24) - (hours * 60 * 60) - (minutes * 60)));

    switch (true) {
        case sec < 60:
            return seconds + '秒'
        case sec <= 60 * 60:
            return `${minutes} 分钟`;
        case sec < 60 * 60 * 24:
            return `${hours} 小时`;
        default:
            return `${days} 天 ${hours}小时`;
    }
}

export const openWindow = (url, title, w, h) => {
    // Fixes dual-screen position                            Most browsers       Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    const left = ((width / 2) - (w / 2)) + dualScreenLeft
    const top = ((height / 2) - (h / 2)) + dualScreenTop
    const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus()
    }
}

// 数字精度取整
export const formatNum = function (f) {
    var m = Math.pow(10, 5);
    return parseInt(f * m, 10) / m;
}

/**
 * 加密处理
 */
export const encryption = (params) => {
    let {
        data,
        type,
        param,
        key
    } = params
    const result = JSON.parse(JSON.stringify(data))
    if (type === 'Base64') {
        param.forEach(ele => {
            result[ele] = btoa(result[ele])
        })
    } else {
        param.forEach(ele => {
            // var data = result[ele]
            // key = CryptoJS.enc.Latin1.parse(key)
            // var iv = key
            //     // 加密
            // var encrypted = CryptoJS.AES.encrypt(
            //     data,
            //     key, {
            //         iv: iv,
            //         mode: CryptoJS.mode.CBC,
            //         padding: CryptoJS.pad.ZeroPadding
            //     })
            // result[ele] = encrypted.toString()
        })
    }
    return result
}

/**
 * 设置浏览器头部标题
 */
export const setTitle = function (title) {
    window.document.title = title
}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
    if (fullscreenEnable()) {
        exitFullScreen()
    } else {
        reqFullScreen()
    }
}

/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
    function listen() {
        callback()
    }

    document.addEventListener('fullscreenchange', function (e) {
        listen()
    })
    document.addEventListener('mozfullscreenchange', function (e) {
        listen()
    })
    document.addEventListener('webkitfullscreenchange', function (e) {
        listen()
    })
    document.addEventListener('msfullscreenchange', function (e) {
        listen()
    })
}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
    var isFullscreen = document.fullscreenEnabled ||
        window.fullScreen ||
        document.mozFullscreenEnabled ||
        document.webkitIsFullScreen
    return isFullscreen
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen()
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen()
    } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
    }
}

/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.exitFullScreen()
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.webkitCancelFullScreen()
    } else if (document.documentElement.mozRequestFullScreen) {
        document.mozCancelFullScreen()
    }
}

/**
 * 递归寻找子类的父类
 */
export const findParent = (menu, id) => {
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].children.length !== 0) {
            for (let j = 0; j < menu[i].children.length; j++) {
                if (menu[i].children[j].id === id) {
                    return menu[i]
                } else {
                    if (menu[i].children[j].children.length !== 0) {
                        return findParent(menu[i].children[j].children, id)
                    }
                }
            }
        }
    }
}


export const exportFile = (blobFile, fileName) => {
    let blob = new Blob([blobFile], { type: 'application/vnd.ms-excel' })
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName//下载后文件名
        document.body.appendChild(downloadElement)
        //此写法兼容火狐
        let evt = document.createEvent('MouseEvents')
        evt.initEvent('click', false, false)
        downloadElement.dispatchEvent(evt)
        document.body.removeChild(downloadElement)
    }
}

export const exportPdf = (blobFile, fileName) => {
    let blob = new Blob([blobFile], { type: 'application/pdf;charset=utf-8' })
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName//下载后文件名
        document.body.appendChild(downloadElement)
        //此写法兼容火狐
        let evt = document.createEvent('MouseEvents')
        evt.initEvent('click', false, false)
        downloadElement.dispatchEvent(evt)
        document.body.removeChild(downloadElement)
    }
}

export const apiDownLoadFile = function (data, type, fileName) {
    const blob = new Blob([data], { type: type });
    const link = document.createElement('a');

    link.style.display = 'none';

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    }
}

export const exportAnchorFile = function (url) {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.click();
    window.document.body.appendChild(link);
    window.URL.revokeObjectURL(link.href);
    window.document.body.removeChild(link);
}

export const generateUid = function () {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';
    return s.join('');
}

export const base64EnCode = function (text) {
    return btoa(encodeURIComponent(text).replace(/%([0-9A-F]{2})/g, (match, p) => {
        return String.fromCharCode(Number('0x' + p));
    }));
}

/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
    let random = ''
    random = Math.ceil(Math.random() * 10000000000000).toString().substr(0, typeof len === 'number' ? len : 4)
    if (date) random = random + Date.now()
    return random
}

export const validateCurrentDataLen = function (del, total, context) {
    if (del.length === total.length && context.listQuery.current > 1) {
        context.listQuery.current = context.listQuery.current - 1;
    }
}

// 获取地区 parent value
export const getParentsValue = function (regionList, target) { //查询某一个节点的所有父节点
    let result = [];
    regionList && regionList.map(item => {
        if (!validatenull(result)) return;
        if (target == item.value) {
            result = [item.value]
        }

        item.children.map(cityItem => {
            if (!validatenull(result)) return;
            if (target == cityItem.value) {
                result = [item.value, cityItem.value]
            }
            cityItem.children.map(countyItem => {
                if (!validatenull(result)) return;

                if (target == countyItem.value) {
                    result = [item.value, cityItem.value, countyItem.value]
                }
            })
        })
    })
    return result;
}

// 获取地区 parent name
export const getParentsName = function (regionList, target) { //查询某一个节点的所有父节点
    let result = [];
    regionList && regionList.map(item => {
        if (!validatenull(result)) return;
        if (target == item.value) {
            result = [item.label]
        }

        item.children.map(cityItem => {
            if (!validatenull(result)) return;
            if (target == cityItem.value) {
                result = [item.label, cityItem.label]
            }
            cityItem.children.map(countyItem => {
                if (!validatenull(result)) return;

                if (target == countyItem.value) {
                    result = [item.label, cityItem.label, countyItem.label]
                }
            })
        })
    })
    return result;
}

export const validatorForm = {
    phone: (rule, value, callback) => {
        if (!validatenull(value) && !reg.phone.test(value)) {
            callback('请输入正确的手机号码!');
        }
        callback();
    },
    email: (rule, value, callback) => {
        if (!validatenull(value) && !reg.email.test(value)) {
            callback('请输入正确的邮箱!');
        }
        callback();
    },
    positiveInteger: (rule, value, callback) => {
        if (!validatenull(value) && !reg.positiveInteger.test(value)) {
            callback('请输入正整数!');
        }
        callback();
    },
}
