import moment from 'moment';
import { reg } from '@/common/validate'

export const filter = {

  /*
  * time 时间 默认接受时间戳，如果不是时间戳 format必填
  * format 描述这个time 的格式
  * */
  dateFormat(time, format, toFormat) {
    if (typeof time === "string" && !format) {
      time = Number(time);
    }
    return new moment(time, format || undefined).format(toFormat || 'YYYY-MM-DD HH:mm:ss');
  },


  pluralize(time, label) {
    if (time === 1) {
      return time + label
    }
    return time + label + 's'
  },
  
  formatMoney(money) {
    return money.toString().replace(reg.formatMoney,'$1,')
  }
}