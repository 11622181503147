import { per } from '@/const/permission';
export default function (IGNORE) {
    return {
        title: '活动管理',
        name: 'active',
        icon: 'icon-agent',
        children: [{
            title: '联通套餐登记',
            path: '/active/unicom',
            name: 'unicomActive',
            component: 'active/list',
            permission: per.SHOW_UNICOM_REG,
            isMenu: false
        }, {
            title: '联通套餐管理',
            path: '/active/unicom/package',
            name: 'unicomPackage',
            component: 'active/package',
            permission: per.SHOW_UNICOM_PRODUCT,
            isMenu: false
        }]
    }
}
