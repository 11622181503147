import request from '@/common/axios';
import store from '@/store';

export function fetchBatteryManList(data) {
    return request({
        url: '/web/admin/user/userPage',
        method: 'POST',
        data: {
            ...data
        }
    });
}


// 修改用户信息
export function updateUserInfo(data) {
    return request({
        url: '/web/admin/station/updateUser',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 用户详情
export function fetchBatteryManDetail(data) {
    return request({
        url: '/web/admin/user/getUserById?userId=' + data.id,
        method: 'GET',
    });
}




export function fetchBatteryManPage(data) {
    return request({
        url: '/web/admin/user/userPage',
        method: 'POST',
        data: {
            ...data,
            pageSize: 1,
            pageIndex: 1
        }
    });
}

// 换电记录
export function fetchExchangeRecord(data) {
    return request({
        url: '/web/admin/battery/userBatteryPage',
        method: 'POST',
        data: data
    });
}

// 用户暂停套餐
export function fetchPauseList(data) {
    return request({
        url: '/web/admin/userPause/userPausePage',
        method: 'POST',
        data: data
    });
}

// 暂停套餐
export function updatePauseProduct() {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 4000,
            oReqData: data
        }
    });
}

// 暂停套餐
export function updateRestoreProduct() {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 4007,
            oReqData: data
        }
    });
}

// 用户订单统计
export function fetchUserOrderStatistics(data) {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 3322,
            oReqData: data
        }
    });
}

// 用户订单按月统计
export function fetchUserOrderStatisticsByMonth(data) {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 3323,
            oReqData: data
        }
    });
}

export function exportUserOrderList(data) {
    return request({
        url: '/excel/userPayTotal/down',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: {
            "iReqCode": 0,
            oReqData: {
                ...data
            },
        }
    });
}

//导出逾期信息 /excel/userPassed/down 
export function exportOverdueDevice(data) {
    return request({
        url: '/web/admin/battery/userOverdueExcelDownload',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: {}
    });
}

// 发送逾期信息
export function sendTimeoutMsg(data) {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 2008,
            "oReqData": data
        }
    });
}


// 设置用户为推广员
export function updatePusher(data) {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 911,
            "oReqData": data
        }
    });
}

// 3天没换电池的用户
export function fetchOverdueUserList(data) {
    return request({
        url: '/web/admin/battery/userBatterOverduePage',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 套餐过期逾期查询
export function fetchOverdueUserList2(data) {
    return request({
        url: '/web/admin/battery/userOverduePage',
        method: 'POST',
        data: {
            ...data
        }
    });
}



// 奖励金导出
export function exportIncomeList(data) {
    return request({
        url: '/excel/userCash/down',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: {
            "iReqCode": 0,
            oReqData: {
                ...data
            },
        }
    });
}

// 用户资产列表
export function fetchUserAssetList(data) {
    return request({
        url: '/web/admin/userAsset/userAssetPage',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 用户资产详情
export function fetchUserAssetDetail(data) {
    return request({
        url: '/web/admin/userAsset/adminUserAssetDetailPage',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 用户提现
export function fetchUserAssetWithdraw(data) {
    return request({
        url: '/web/admin/userAsset/UserWithdrawDepositRecordPage',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 修改用户资产
export function updateUserAssets(data) {
    return request({
        url: '/web/admin/userAsset/sysAdjustUserAsset',
        method: "POST",
        data: data
    })
}

// 查询用户提现结果
export function updateUserWithdrawResult(data) {
    return request({
        url: '/web/admin/userAsset/updateWithdrawDepositStatus',
        method: "POST",
        data: data
    })
}

// 用户提现审核
export function userWithdrawAudit(data) {
    return request({
        url: '/web/admin/userAsset/audit',
        method: "POST",
        data: data
    })
}

// 导出用户资产
export function exportUserAssetsList(data) {
    return request({
        url: '/web/admin/userAsset/assetDetailExcelDownload',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: data
    });
}


// 交换绑定的微信
export function exchangeBindWx(data) {
    return request({
        url: '/web/admin/user/bind/exchangeWx',
        method: 'post',
        data: data,
    });
}

// 导出用户提现
export function exportUserWithdrawList(data) {
    return request({
        url: '/web/admin/userAsset/withdrawExcelDownload',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: data
    });
}

// 导出用户邀请
export function exportUserinviteList(data) {
    return request({
        url: '/web/admin/userInviteRecord/inviteRecordExcelDownload',
        unsys: true,
        method: 'POST',
        responseType: 'arraybuffer',
        data: data
    });
}



// 是否允许这个用户提现
export function updateAllowUserWithdraw(data) {
    return request({
        url: '/userInfo/assetStatusUpdate',
        method: "POST",
        data: data
    })
}

// 修改用户pusher类型 /sys/userInfo/userRoleChange
export function updateUserPusherType(data) {
    return request({
        url: '/web/admin/user/setRoleType',
        method: "POST",
        data: data
    })
}

// 用户pusher 奖励金规则
export function updateUserPusherAwardRule(data) {
    return request({
        url: '/web/admin/user/updateZzRewardRule',
        method: "POST",
        data: data
    })
}


// 站长列表  
export function fetchPusherList(data) {
    return request({
        url: '/web/admin/user/userPage',
        method: "POST",
        data: {
            ...data
        }
    })
}

// 站长信息详情
export function fetchPuserInfo(data) {
    console.log(data, "fetchPuserInfo");
    return request({
        url: '/web/admin/user/getZzRewardRuleListByUserId?userId=' + data.userId,
        method: "get",
    })
}

// 用户邀请记录
export function fetchUserInviteRecord(data) {
    return request({
        url: '/web/admin/userInviteRecord/userInviteRecordPage',
        method: "POST",
        data: data
    })
}

// 修改用户套餐
export function updateUser(data) {
    return request({
        url: '/web/admin/user/updateUser',
        method: "POST",
        data: data
    })
}

// 修改用户逾期备注
export function addOverdueRemark(data) {
    return request({
        url: '/web/admin/battery/updateOverdueRemark',
        method: "POST",
        data: data
    })
}

// 暂停套餐
export function pauseProduct(data) {
    return request({
        url: '/web/admin/userPause/addUserPause',
        method: "POST",
        data: data
    })
}

// 
export function doEnd(data) {
    return request({
        url: '/web/admin/user/overLease',
        method: "POST",
        data: data
    })
} 