<!-- 导入中... 提示 -->
<template>
    <el-dialog
            class="loading-modal"
            :visible.sync="isShow"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
    >
        <div style="height: 130px;" v-loading="true"></div>
        <div class="text-c">数据正在导入</div>
    </el-dialog>
</template>

<script>
    import modalMixin from '@/mixins/modalMixin'

    export default {
        mixins: [modalMixin],

        data() {
            return {}
        }
    }
</script>
<style lang="less">
    .loading-modal {
        .el-dialog__header {
            border: 0;
        }
    }
</style>
