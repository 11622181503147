import { per } from '@/const/permission';
export default function (IGNORE) {
    return {
        title: '在网数据',
        name: 'online',
        icon: 'icon-online',
        children: [
            {
                title: '套餐购买记录',
                path: '/order/product',
                name: 'order-product',
                component: 'order/product',
                permission: per.SHOW_PRODUCT_RECORD,
                isMenu: true
            }, {
                title: '换电事件',
                path: '/user/event',
                name: 'user-event',
                component: 'user/user-event',
                permission: per.SHOW_PENALTY_RECORD,
                isMenu: true
            },{
                title: '换电记录',
                path: '/order/battery',
                name: 'order-battery',
                component: 'order/battery',
                permission: per.SHOW_PENALTY_RECORD,
                isMenu: true
            },  {
                title: '开门记录',
                path: '/order/cabinet',
                name: 'order-cabinet',
                component: 'order/cabinet',
                permission: per.SHOW_STATION_BATTERY,
                isMenu: true
            }, {
                title: '站点列表',
                path: '/station/list',
                name: 'station-list',
                component: 'station/list',
                permission: per.SHOW_STATION_LIST,
                isMenu: true,
                meta: {
                    keepAlive: true
                }
            }, {
                title: '站点电池',
                path: '/station/battery',
                name: 'station-battery',
                component: 'station/battery',
                permission: per.SHOW_STATION_BATTERY,
                isMenu: true,
                meta: {
                    keepAlive: true
                }
            }, {
                title: '站点详情',
                path: '/station/detail/:id',
                name: 'station-detail',
                component: 'station/detail',
                permission: per.SHOW_STATION_DETAIL,
                isMenu: false
            }, {
                title: '用户列表',
                path: '/user/list',
                name: 'user-list',
                component: 'user/list',
                isMenu: true,
                permission: per.SHOW_USER_LIST,
                meta: {
                    keepAlive: true
                }
            }, {
                title: '用户详情',
                path: '/user/detail/:id',
                name: 'user-detail',
                component: 'user/detail',
                permission: per.SHOW_USER_DETAIL,
                isMenu: false,
            },  {
                title: '用户优惠券',
                path: '/user/coupon',
                name: 'user-coupon',
                component: 'user/coupon',
                permission: per.SHOW_USER_CONPON,
                isMenu: true,
            }]
    }
}
