<template>
  <el-select
    :remote-method="searchUser"
    :remote="true"
    filterable
    clearable
    placeholder="用户姓名"
    v-model="userId"
    @change="changeSelect"
  >
    <el-option
      v-for="(item, index) in userList"
      :value="item.id"
      :key="index"
      :label="item.realName"
    >
       <span style="float: left">{{ item.realName }}</span>
            <span
              style="
                float: right;
                color: var(--el-text-color-secondary);
                font-size: 13px;
              "
              >电话：{{item.phone}}</span
            >
    </el-option>
  </el-select>
</template>

<script>
import { fetchBatteryManList } from "@/api/batteryMan";
import { toRefs, toRef, reactive } from "vue";
import { validatenull } from "@/common/validate";

export default {
  emits: ["change"],
  setup(props, context) {
    const state = reactive({
      userList: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      userId: "",
      listQuery: {
        
      },
    });

    const searchUser = (e) => {
      if (validatenull(e)) {
        this.userList = [];
        return;
      }
      state.listQuery.realName = e;
      fetchBatteryManList({...state.listQuery,
        ...state.pages,
      }).then((res) => {
        state.userList = res.data.data.records;
      });
    };

    const changeSelect = (e) => {
      let { emit } = context;
      console.log(emit);
      context.emit("update:modelValue", e);
      context.emit("change");
    };

    return {
      ...toRefs(state),
      searchUser,
      changeSelect,
    };
  },
};
</script>