<template>
  <span v-if="curData.style"
    ><i :class="`fpl-badge-${curData.style}`" class="fpl-badge"></i
    ><span>{{ curData.name }}</span></span
  >
  <span v-else>{{ curData.name }}</span>
</template>

<script>
export default {
  props: ["list", "target", "keys"],
  name: "my-badge",
  data() {
    return {
      curData: {},
    };
  },

  methods: {
    getCurData(target) {
      this.curData = {};
      let key = this.keys || "value";
      this.list &&
        this.list.map((item) => {
          if (item[key] == target) {
            this.curData = item;
          }
        });
    },
  },

  watch: {
    target: function (newValue) {
      this.getCurData(newValue);
    },
  },

  mounted() {
    this.getCurData(this.target);
  },

  created() {},
};
</script>

<style lang="less">
.fpl-badge {
  margin-right: 5px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  display: inline-block;

  &.fpl-badge-error {
    background: #ff5f36;
  }

  &.fpl-badge-success {
    background: #55d5be;
  }

  &.fpl-badge-progress {
    background: #30a5ff;
  }
  &.fpl-badge-info {
    background: #aec0ee;
  }

  &.fpl-badge-warnning {
    background: #f3a484;
  }

  &.fpl-badge-default {
    background: #d9d9d9;
  }
}
</style>
