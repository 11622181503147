<template>
  <el-container class="wrapper-layout">
    <el-aside :class="asideClass" :width="asideWidth">
      <div class="sidebar-logo">
        <img src="@/assets/image/logo.png" />
        <!-- <span class="title">易安换电</span> -->
      </div>
      <sider-menu ref="menu" />
    </el-aside>

    <el-container class="main-layout" :style="{marginLeft: asideWidth}">
      <el-header class="page-header" height="90px">
        <app-Header />
      </el-header>

      <el-main class="page-main">
         <div class="container-header" flex-box="0">
                <d2-tabs />
              </div>
        <app-main class="page-content" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {mapState} from 'vuex';
import SiderMenu from './SiderMenu.vue';
import AppHeader from './AppHeader.vue';
import AppMain from './AppMain.vue';
import {IGNORE} from '@/router/nav';
import d2Tabs from "@/components/tabs";

export default {
  name: 'AdminLayout',
  components: {
    SiderMenu,
    AppHeader,
    AppMain,
    d2Tabs
  },
  computed: {
    ...mapState({
      asideWidth: (state) => {
        return !state.sidebarOpened ? '64px' : '210px';
      },
      asideClass: (state) => {
        return {
          hideSidebar: !state.sidebarOpened,
          openSidebar: state.sidebarOpened
        };
      }
    })
  },
  mounted() {
    let curPermission = this.$router.currentRoute._value.meta.permission;
    // this.$store.dispatch('getUserPermission').then((res) => {
    //   this.$refs.menu.updateMenu();
    //   console.log(!this.$store.getters.permissions[curPermission] , curPermission !== IGNORE,curPermission,'=================1');
    //   if (!this.$store.getters.permissions[curPermission] && curPermission !== IGNORE) {
    //     //没有token
    //     this.$message.error('您没有权限进入该页面！');
    //     this.$router.push({path: '/login'});
    //   }
    // });

    document.title = this.$store.getters.getSetting.title;
  }
};
</script>

<style lang="less">
.wrapper-layout {
  .page-header {
   
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .page-main {
    background: #f1f1f1;
    padding: 0 20px;
  }

  .page-content > div {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  }

  .el-aside {
    transition: width 0.28s;
    background-color: #304156;
    height: 100%;
    position: fixed;
    font-size: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
  }

  .sidebar-logo {
    height: 70px;
    line-height: 60px;
    padding: 5px 0;
    background-color: #d8d8d8;

    a {
      display: flex;
      height: 100%;
    }

    img {
      transition: all 0.28s;
      width: 125px;
      margin-left: 5px;
    }

    .title {
      font-size: 18px;
      color: #e7e7e7;
      font-weight: 600;
      margin-left: 10px;
    }
  }
}

.main-layout {
  min-height: 100%;
  transition: margin-left 0.28s;
  position: relative;

  .el-main {
    min-height: calc(100vh - 90px);
    width: 100%;
    min-width: 1000px;
    position: relative;
    overflow: hidden;
  }
}
</style>
