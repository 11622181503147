import { getStore, setStore, clearStore } from '@/common/store';
import { fetchBicycleAgentAllList } from '@/api/bicycle';

const bicycleAgent = {
    state: {
        list: getStore({
            name: 'list'
        }) || [] ,
    },
    actions: {
        getBicycleAgentListAll({ commit, state, dispatch }, params) {
            return new Promise((solve, reject) => {
                fetchBicycleAgentAllList().then(res => {
                    commit('SET_LIST', res.data.data);
                    solve();
                })
            })
        },
    },
    mutations: {
        SET_LIST(state, agentList) {
            state.list = agentList;
            setStore({
                name: 'bicycleAgentList',
                content: state.list,
                type: 'session'
            })
        },
    }
}

export default bicycleAgent;
