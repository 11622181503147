import request from '@/common/axios';

// 换电记录统计 日
export function exchangeRecordDay(data) {
  return request({
    url: '/web/admin/station/selectStationCount',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 换电记录统计 
export function statisticsExchangeRecord(data) {
  return request({
    url: '/web/admin/count/changeCount',
    method: 'POST',
    data: data
  });
}

 

// 新增用户统计 日
export function newUserDay(data) {
  return request({
    url: '/web/admin/count/newUserCount',
    method: 'post',
    data: data
  });
}

// 新增用户统计 月
export function newUserMonth(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 3311,
      oReqData: {
        ...data
      }
    }
  });
}

// 系统总数统计  
export function statisticsAll(data) {
  return request({
    url: '/web/admin/count/sysCount',
    method: 'GET',
  });
}

// 获取实时换电记录
export function fetchRealTimeExchangeRecord(data) {
  return request({
    url: '/web/admin/count/changeCount',
    method: 'POST',
    data: {
      "endTime": "",
      "startTime": ""
    }
  });
}



// 站点数据统计
export function fetchStationStatistics(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 3321,
      oReqData: {
        ...data
      }
    }
  });
}

// 查询换电数站点排名top10
export function fetchStationTop10(data) {
  return request({
    url: '/web/admin/count/stationTop',
    method: 'POST',
    data: data
  });
}

/** 用户平均换电次数 **/
export function fetchUserAvgExchange(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 3313,
      "oReqData": data,
      "iReqSerial": 1
    }
  });
}

// 用户平均换电数导出
export function exportAvgUserUse(data) {
  return request({
    url: '/excel/userUse/down',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: {
      "iReqCode": 0,
      "oReqData": data,
    }
  });
}

/** 用户订单统计 */
export function fetchUserOrder(data) {
  return request({
    url: '/web/admin/count/orderCount',
    method: 'POST',
    data: data,
  })
}

// 用户来源统计
export function fetchUserSource(data) {
  return request({
    url: '/web/admin/count/userCount',
    method: 'get',
    data: data,
  })
}

// 站点换电数导出
export function exportStationExchange(data) {
  return request({
    url: '/excel/electrTotal/down',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: {
      "iReqCode": 0,
      "oReqData": data,
    }
  });
}