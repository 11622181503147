<template>
    <div class="verify"><img :src="baseUrl+code+pageid" class="img"></div>
</template>

<script>
    export default {
        props: ['pageid'],
        data() {
            return {
                baseUrl: this.baseUrl,
                code: '/api/login/code?pageid='
            };
        }
    }
</script>

<style lang="less" scoped>
    .verify {
        display: inline-block;
        height: 33px;
        width: 121px;
        cursor: pointer;
        border: 1px solid #e1e1e1;
        vertical-align: middle;
    }

    .img {
        height: 32px;
        clip: rect(1px 119px 31px 1px);
        position: absolute;
    }
</style>
