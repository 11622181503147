import { per } from '@/const/permission';

export default function (IGNORE) {
    return {
        title: '设备管理',
        name: 'device',
        icon: 'icon-device',
        children: [{
            title: '电池列表',
            path: '/device/battery',
            name: 'device-battery',
            component: 'device/battery',
            permission: per.SHOW_BATTERY_LIST,
            isMenu: true,
            meta: {
                keepAlive: true
            }
        }, {
            title: '电池详情',
            path: '/device/battery/detail/:id',
            name: 'device-battery-detail',
            component: 'device/battery-detail',
            permission: per.SHOW_BATTERY_DETAIL,
            isMenu: false
        },{
            title: '跳电电池',
            path: '/device/battery/error',
            name: 'device-battery-error',
            component: 'device/battery-error',
            permission: per.SHOW_BATTERY_ERROR,
            isMenu: true
        },{
            title: '销售电池',
            path: '/device/battery/sale',
            name: 'device-battery-sale',
            component: 'device/sale-battery',
            permission: per.SHOW_BATTERY_ERROR,
            isMenu: true
        }]
    }
}
