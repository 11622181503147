import request from '@/common/axios';
/**
 * 系统用户
 */

// 系统登录
export function login(data) {
  return request({
    url: '/web/admin/admin/login',
    method: 'POST',
    data: data
  });
}

// 更新用户token
export function doRefreshToken(data) {
  return request({
    url: '/getNewToken',
    method: 'POST',
    headers: {
      token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxIiwicGhvbmUiOiIiLCJleHAiOjE2Njk3MTczNjQsInVzZXJOYW1lIjoiYWRtaW4iLCJpYXQiOjE2Njk3MTM3NjR9.corrcKbaObfPIK-TgtAQjPqrHMCjmW5V4sLiLOerGxk"
    },
    data: data
  });
}



// 用户列表
export function fetchUserList(data) {
  return request({
    url: '/web/admin/admin/adminPage',
    method: 'POST',
    data: data
  });
}





// 添加用户
export function addUser(data) {
  return request({
    url: '/web/admin/admin/add',
    method: 'POST',
    data: data
  });
}

// 修改用户
export function updateUser(data) {
  return request({
    url: '/web/admin/admin/updateAdmin',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 删除用户
export function delUser(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 201,
      oReqData: {
        ...data
      }
    }
  });
}

// 修改密码
export function updatePwd(data) {
  return request({
    url: '/web/admin/admin/updateAdminPassword',
    method: 'POST',
    data: data
  });
}

// 修改自己密码
export function updateMyPwd(data) {
  return request({
    url: '/web/admin/admin/updateMyPassword',
    method: 'POST',
    data: data
  });
}

// 添加角色
export function updateUserRole(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 400,
      "oReqData": data,
    }
  });
}


// 用户优惠券
export function fetchUserCoupont(data) {
  return request({
    url: '/web/admin/userCoupon/adminUserCouponPage',
    method: 'POST',
    data: {
      ...data,
    }
  });
}

// 发放优惠券
export function addUserCoupont(data) {
  return request({
    url: '/web/admin/userCoupon/sendUserCoupon',
    method: 'POST',
    data: data
  });
}

// 用户邀请
export function fetchUserInviteRecord(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 3105,
      "oReqData": data,
    }
  });
}

// 查询推荐员列表
export function fetchPresenterList(data) {
  return request({
    url: '/web/admin/userPushApply/userPushApplyPage',
    method: 'POST',
    data: data
  });
}

// 审核推荐员
export function presenterAudit(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 4708,
      "oReqData": data
    }
  });
}

// 用户绑定推荐者
export function bindInviter(data) {
  return request({
    url: '/web/admin/user/bind/inviter',
    method: 'POST',
    data: {
     ...data
    }
  });
}

// 用户提现列表
export function fetchUserWithdrawList(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 3205,
      "oReqData": data
    }
  });
}

// 用户提现审核
export function userWithdrawAudit(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 3207,
      "oReqData": data
    }
  });
}

// 用户线下转账
export function userOfflineWithdraw(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 3208,
      "oReqData": data
    }
  });
}

// 转账查询
export function fetchWithdrawResult(data) {
  return request({
    url: '/web/admin/userAsset/updateWithdrawDepositStatus',
    method: 'POST',
    data: data
  });
}

// 推荐员提现信息导出
export function exportWithdrawList(data) {
  return request({
    url: '/excel/userBalance/down',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: {
      "iReqCode": 0,
      "oReqData": data,
    }
  });
}

// 获取当前用户的权限
export function fetchUserPermission() {
  return request({
    url: '/web/admin/role/getButtonListByAdmin',
    method: 'get'
  });
}


// 用户积分记录
export function fetchScorePage(data) {
  return request({
    url: '/web/admin/scoreLog/page',
    method: 'post',
    data:data,
  });
}
