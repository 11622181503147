import { per } from '@/const/permission';

export default function (IGNORE) {
    return {
        title: '订单管理',
        name: 'order',
        icon: 'icon-order',
        children: [{
            title: '套餐购买记录',
            path: '/order/product',
            name: 'order-product',
            component: 'order/product',
            permission: per.SHOW_PRODUCT_RECORD,
            isMenu: true
        }, {
            title: '退款记录',
            path: '/order/refund',
            name: 'order-refund',
            component: 'order/order-refund',
            permission: per.SHOW_PENALTY_RECORD,
            isMenu: true
        }]
    }
}
