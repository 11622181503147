import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/global.css';
import '@/assets/less/element.less'
import ElementPlus from 'element-plus'
import { validatenull } from '@/common/validate';
import * as util from '@/common/util';
import 'element-plus/dist/index.css'
import '@/assets/less/base.less'
import * as constant from '@/const/index';
import component from '@/components';
import { filter } from '@/filter/index';
import directive from '@/directives/index';
import '@/assets/font/iconfont.css';
import {per} from '@/const/permission';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import {menuList} from '@/router/nav'
import moment from 'moment';
let app = createApp(App);

moment.locale('zh-cn');
app.use(ElementPlus, { size: 'small', zIndex: 3000, locale: zhCn })
app.use(router);
app.use(store);
app.use(directive);
app.use(component);

app.config.globalProperties.$filter = filter;
app.config.globalProperties.validatenull = validatenull;
app.config.globalProperties.deepClone = util.deepClone;
app.config.globalProperties.validateCurrentDataLen = util.validateCurrentDataLen;
app.config.globalProperties.const = constant;
app.config.globalProperties.util = util;

app.config.globalProperties.per = per;


app.mount('#app')
store.commit('page/init', menuList)
store.dispatch('page/openedLoad', null, {root: true})
export default app;