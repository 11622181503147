// 推广用户类型
export const PUSHER_USER_TYPE_CODE = {
  STATION: 2,
  SHOP: 1,
  NORMAL: 0
}

export const PUSHER_USER_TYPE = [
  { name: '站长推广员', value: PUSHER_USER_TYPE_CODE.STATION },
  { name: '车店推广员', value: PUSHER_USER_TYPE_CODE.SHOP },
  { name: '普通用户', value: PUSHER_USER_TYPE_CODE.NORMAL },
]

// 奖励金状态
export const REWARD_STATUS_CODE = {
  SETTLE: 20,
  UN_SETTLE: 10
}

export const REWARD_STATUS = [
  { name: '已结算', value: REWARD_STATUS_CODE.SETTLE },
  { name: '未结算', value: REWARD_STATUS_CODE.UN_SETTLE },
]
// 换电类型
export const EXCHANGE_TYPE_CODE = {
  BORROW: 1,
  EXCHANGE: 2,
  BACK: 3,
  RECHARGE: 4,
  TEMP: 5,
  TEMP_BACK: 6,
  RECHARGE_BACK: 7,
}

// 押金类型 押金支付方式 1.平台收款 2.支付宝免押预扣款 3.帮代理代收
export const DEPOSIT_TYPE_CODE = {
  PLAT: 1,
  ALI: 2,
  AGENT: 3,
}

export const DEPOSIT_TYPE = [
  { name: '平台收款', value: DEPOSIT_TYPE_CODE.PLAT },
  { name: '芝麻免押', value: DEPOSIT_TYPE_CODE.ALI },
  { name: '代理免押', value: DEPOSIT_TYPE_CODE.AGENT },
]
// 1.借电 2.换电 3.还电, 4 充电, 5， 临时借电, 6:临时取电， 7：充电取电
export const EXCHANGE_TYPE = [
  { name: '借电', value: EXCHANGE_TYPE_CODE.BORROW },
  { name: '换电', value: EXCHANGE_TYPE_CODE.EXCHANGE },
  { name: '退电', value: EXCHANGE_TYPE_CODE.BACK },
  { name: '充电', value: EXCHANGE_TYPE_CODE.RECHARGE },
  { name: '充电取电', value: EXCHANGE_TYPE_CODE.RECHARGE_BACK },
  { name: '临时借电', value: EXCHANGE_TYPE_CODE.TEMP },
  { name: '临时退电', value: EXCHANGE_TYPE_CODE.TEMP_BACK },
]

// 代理商用户状态 支骑手套餐状态 1.待支付 2.待激活 3.使用中 4.已归还
export const PACK_STATUS_CODE = {
  WAIT_PAY: 1,
  WAIT_ACTIVITY: 2,
  USE: 3,
  END: 4
}

export const USER_STATUS = [
  { name: "待支付", value: PACK_STATUS_CODE.WAIT_PAY },
  { name: "待激活", value: PACK_STATUS_CODE.WAIT_ACTIVITY },
  { name: "使用中", value: PACK_STATUS_CODE.USE },
  { name: "已归还", value: PACK_STATUS_CODE.END },
]

// 
export const SOURCE_TYPE_CODE = {
  BUY_PRODUCT: "1",
  REFUND_PRODUCT: "2"
}

// 先用后付订单状态 1.未结清，2已结清，3.结清待补偿 compensation
export const ZZ_AGENT_ORDER_STATUS_CODE = {
  UNSETTLE: "1",
  SETTLE: "2",
  SETTLE_COMPENSATE: "3",
}


export const ZZ_AGENT_ORDER_STATUS = [
  { name: "未结清", value: ZZ_AGENT_ORDER_STATUS_CODE.UNSETTLE },
  { name: "已结清", value: ZZ_AGENT_ORDER_STATUS_CODE.SETTLE },
  { name: "结清待补偿", value: ZZ_AGENT_ORDER_STATUS_CODE.SETTLE_COMPENSATE },
]

// 用户状态   1.新增 2.使用中 3.离职，4
export const AGENT_USER_STATUS_CODE = {
  WITH_ADD: 1,
  USE: 2,
  END: 3,
  OVERDUE: 4
}

export const AGENT_USER_STATUS = [
  { name: '新增', value: AGENT_USER_STATUS_CODE.WITH_ADD, style: 'progress' },
  { name: '使用中', value: AGENT_USER_STATUS_CODE.USE, style: 'success' },
  { name: '离职', value: AGENT_USER_STATUS_CODE.END, style: 'error' },
]

export const SOURCE_TYPE = [
  { name: '购买套餐', value: SOURCE_TYPE_CODE.BUY_PRODUCT },
  { name: '套餐退款', value: SOURCE_TYPE_CODE.REFUND_PRODUCT },
]

// 换电结果
export const EXCHANGE_RESULT_CODE = {
  SUCCESS: 1,
  FAIL: 2,
  DEFAULT: 0
}

export const EXCHANGE_RESULT = [
  { name: "默认", value: EXCHANGE_RESULT_CODE.DEFAULT },
  { name: "成功", value: EXCHANGE_RESULT_CODE.SUCCESS, style: "success" },
  { name: "失败", value: EXCHANGE_RESULT_CODE.FAIL, style: 'error' },
]

export const EXCHANGE_STATUS_CODE = {
  PROGRESS: 0,
  END: 1
}

export const EXCHANGE_STATUS = [
  { name: "进行中", value: EXCHANGE_STATUS_CODE.PROGRESS, style: "progress" },
  { name: "结束", value: EXCHANGE_STATUS_CODE.END, style: 'success' },
]

export const STATION_STATUS_CODE = {
  ABLE: 1,
  DISABLE: 0
}

export const STATION_STATUS = [
  { name: "启用", value: STATION_STATUS_CODE.ABLE, style: "progress" },
  { name: "禁用", value: STATION_STATUS_CODE.DISABLE, style: 'error' },
]

export const STATION_ONLINE_CODE = {
  ON: 1,
  OFF: 2
}


export const STATION_ONLINE = [
  { name: "在线", value: STATION_ONLINE_CODE.ON, style: "success" },
  { name: "离线", value: STATION_ONLINE_CODE.OFF, style: 'error' },
]




// 管理员代理商
export const ADMIN_OPERATION = 1;
//  s.replace(/.*?=(\w+)?\;\/\/(.*?)(pub|$)/g,'"$1":"$2",')
// 用户类型   10本公司管理员，11公司员工，20代理商管理员，21代理商员工 30分销商管理员，31分销商员工
export const ROLE_TYPE_CODE = {
  SYS_MANAGE: 10,
  SYS_EMPLOYEE: 11,
  AGENT_MANAGE: 20,
  AGENT_EMPLOYEE: 21,
  DISTRUBUTION_MANAGE: 30,
  DISTRUBUTION_EMPLOYEE: 31
}

// 1APP 2储能柜 3换电柜 4电池控制板
export const SOFTWARE_TYPE_CODE = {
  APP: 1,
  CABINET: 2,
  EXCHANGE_CABINET: 3,
  BATTERY_CONTROL: 4
}

export const SOFTWARE_TYPE = [
  { name: 'APP', value: SOFTWARE_TYPE_CODE.APP },
  { name: '储能柜', value: SOFTWARE_TYPE_CODE.CABINET },
  { name: '换电柜', value: SOFTWARE_TYPE_CODE.EXCHANGE_CABINET },
  { name: '电池控制板', value: SOFTWARE_TYPE_CODE.BATTERY_CONTROL },
]

// 站点类型 1普通站点，2储能柜 3换电柜
export const STATION_TYPE_CODE = {
  OFFLINE: 1,// 线下站点
  CABINET: 2, // 储能柜
  EXCHANGE_CABINET: 3
}

export const STATION_TYPE = [
  { name: '线下网点', value: STATION_TYPE_CODE.OFFLINE },
  { name: '储能柜', value: STATION_TYPE_CODE.CABINET },
  { name: '换电柜', value: STATION_TYPE_CODE.EXCHANGE_CABINET },
]

// 换电柜是否开了仓门
export const BOX_DOOR_OPEN_CODE = {
  IS_OPEN: 1,
  IS_CLOSE: 2
}

export const BOX_DOOR_OPEN = [
  { name: '仓门打开', value: BOX_DOOR_OPEN_CODE.IS_OPEN, style: "error" },
  { name: '仓门关闭', value: BOX_DOOR_OPEN_CODE.IS_CLOSE, style: "success" },
]

// 换电柜是否开了禁用
export const BOX_DOOR_BAN_CODE = {
  IS_BAN: 0,
  NORMAL: 1
}

export const BOX_DOOR_BAN = [
  { name: '已禁用', value: BOX_DOOR_BAN_CODE.IS_BAN, style: "error" },
  { name: '正常', value: BOX_DOOR_BAN_CODE.NORMAL, style: "success" },
]

// 1普通用户 2系统用户
export const USER_TYPE_CODE = {
  USER: 1,
  ADMIN: 2,
}

export const USER_TYPE = [
  { name: '普通用户', value: USER_TYPE_CODE.USER },
  { name: '系统用户', value: USER_TYPE_CODE.ADMIN },
]



//  1启用，2停用
export const RULE_STATUS_CODE = {
  ABLE: 1,
  DISABLED: 2,
}

export const RULE_STATUS = [
  { name: '启用', value: RULE_STATUS_CODE.ABLE, style: "success" },
  { name: '停用', value: RULE_STATUS_CODE.DISABLED, style: 'error' },
]


// 优惠券有效期类型
export const COUPON_EXPIRE_CODE = {
  DATE: 1,
  DAY: 2
}

//  1上线，2下线，4删除
export const COUPON_STATUS_CODE = {
  SOLD_OUT: 2,
  PUTWAY: 1,
}

export const COUPON_STATUS = [
  { name: '已上架', value: COUPON_STATUS_CODE.PUTWAY, style: "success" },
  { name: '已下架', value: COUPON_STATUS_CODE.SOLD_OUT, style: 'error' },
]



// 消息类型
export const MESSAGE_TYPE_CODE = {
  USER: 1,
  AGENT: 2,
  ALL: 3
}

export const MESSAGE_TYPE = [
  { name: '用户公告', value: MESSAGE_TYPE_CODE.USER },
  { name: '代理商公告', value: MESSAGE_TYPE_CODE.AGENT },
  { name: '全部', value: MESSAGE_TYPE_CODE.ALL },
]

// 用户设备状态  1使用中，2归还
export const USER_DEVICE_STATUS_CODE = {
  USE: 1,
  RETURN: 2,
}

export const USER_DEVICE_STATUS = [
  { name: '使用中', value: USER_DEVICE_STATUS_CODE.USE, style: "progress" },
  { name: '已归还', value: USER_DEVICE_STATUS_CODE.RETURN, style: "success" },
]




//  是否使用
export const IS_USE_CODE = {
  USE: 1,
  UNUSE: 0,
  EXPIRE: 2
}

export const IS_USE = [
  { name: '已使用', value: IS_USE_CODE.USE, style: "default" },
  { name: '未使用', value: IS_USE_CODE.UNUSE, style: "success" },
  { name: '已过期', value: IS_USE_CODE.EXPIRE, style: "error" },
]


// 暂停状态 1暂停 2已恢复
export const PRODUCT_PAUSE_TYPE_CODE = {
  PAUSE: 1,
  RESTORE: 2,
}

export const PRODUCT_PAUSE_TYPE = [
  { name: '暂停', value: PRODUCT_PAUSE_TYPE_CODE.PAUSE, style: "progress" },
  { name: '已恢复', value: PRODUCT_PAUSE_TYPE_CODE.RESTORE, style: "success" },
]

// 换电柜开门状态 10开门 11开门异常,20关门 21关门异常,30未知错误
export const OPEN_DOOR_TYPE_CODE = {
  OPEN_DOOR: 10,
  OPEN_DOOR_ERROR: 11,
  CLOSE_DOOR: 20,
  CLOSE_DOOR_ERROR: 21,
  OTHER_ERROR: 30,
}

export const OPEN_DOOR_TYPE = [
  { name: '开门', value: OPEN_DOOR_TYPE_CODE.OPEN_DOOR, style: "success" },
  { name: '开门异常', value: OPEN_DOOR_TYPE_CODE.OPEN_DOOR_ERROR, style: "error" },
  { name: '关门', value: OPEN_DOOR_TYPE_CODE.CLOSE_DOOR, style: "success" },
  { name: '关门异常', value: OPEN_DOOR_TYPE_CODE.CLOSE_DOOR_ERROR, style: "error" },
  { name: '未知错误', value: OPEN_DOOR_TYPE_CODE.OTHER_ERROR, style: "error" },
]


// 用户退押金  10申请中 11 通过 12 驳回 20退款中 30已退款
export const RETURN_DEPOSIT_STATUS_CODE = {
  APPLY: 10,
  PASS: 11,
  REJECT: 12,
  RETURNING: 20,
  RETURNED: 30,
  REJECT: 40
}


export const RETURN_DEPOSIT_STATUS = [
  { name: '申请中', value: RETURN_DEPOSIT_STATUS_CODE.APPLY, style: "default" },
  { name: '审核通过', value: RETURN_DEPOSIT_STATUS_CODE.PASS, style: "default" },
  { name: '审核驳回', value: RETURN_DEPOSIT_STATUS_CODE.REJECT, style: "error" },
  { name: '退款中', value: RETURN_DEPOSIT_STATUS_CODE.RETURNING, style: "progress" },
  { name: '已退款', value: RETURN_DEPOSIT_STATUS_CODE.RETURNED, style: "success" },
]

// 推荐奖励类型 1用户推荐奖励 2代理商推荐注册 3优惠券奖励
export const BONUS_RULE_TYPE_CODE = {
  USER: 1,
  AGENT: 2,
  COUPON: 3
};
export const BONUS_RULE_TYPE = [
  { name: '用户推荐奖励', value: BONUS_RULE_TYPE_CODE.USER },
  { name: '代理商推荐奖励', value: BONUS_RULE_TYPE_CODE.AGENT },
  { name: '优惠券奖励', value: BONUS_RULE_TYPE_CODE.COUPON },
];

// 审核状态 
export const AUDIT_STATUS_CODE = {
  WAIT: 0,
  PASS: 1,
  REJECT: 2, AUDIT_STATUS_CODE
}

export const AUDIT_STATUS = [
  { name: '待审核', value: AUDIT_STATUS_CODE.WAIT, style: "progress" },
  { name: '审核通过', value: AUDIT_STATUS_CODE.PASS, style: "success" },
  { name: '审核不通过', value: AUDIT_STATUS_CODE.REJECT, style: "error" },
]

// 代理商等级  1本公司 2一级代理 3二级代理
export const AGENT_LEVEL_CODE = {
  SELF: 1, // 本公司
  PRIMARY: 2, // 一级代理商
  SECONDARY: 3, // 二级代理商
}

export const AGENT_LEVEL = [
  { name: '一级代理商', value: AGENT_LEVEL_CODE.PRIMARY },
  { name: '二级代理商', value: AGENT_LEVEL_CODE.SECONDARY },
]

// 状态， 
export const STATUS_CODE = {
  DISABLED: 0,
  ABLE: 1
}

export const STATUS = [
  { name: '正常', value: STATUS_CODE.ABLE, style: 'success' },
  { name: '禁用', value: STATUS_CODE.DISABLED, style: 'error' }
]

// 商品上架
export const PUTWAY_CODE = {
  SOLD_OUT: 2,
  PUTWAY: 1,
}

export const PUTWAY = [
  { name: '已上架', value: PUTWAY_CODE.PUTWAY, style: "success" },
  { name: '已下架', value: PUTWAY_CODE.SOLD_OUT, style: 'error' }
]

// 收益来源 1推荐套餐 2奖励 3 站点提成 4下级站点提成 
export const INCOME_TYPE_CODE = {
  REGISTER: 1,
  BOUNTY: 2,
  STATION: 3,
  SUBORDINATE: 4,
}

export const INCOME_TYPE = [{
  name: '推荐套餐',
  value: INCOME_TYPE_CODE.REGISTER
},
{
  name: '奖励',
  value: INCOME_TYPE_CODE.BOUNTY
},
{
  name: '站点提成',
  value: INCOME_TYPE_CODE.STATION
},
{
  name: '下级站点提成',
  value: INCOME_TYPE_CODE.SUBORDINATE
},
]

// 电池工作状态  1:充电中  2 待机
export const BATTERY_WORK_STATUS_CODE = {
  CHARGE: 1,
  WAIT: 2,
}

export const BATTERY_WORK_STATUS = [
  { name: '充电中', value: BATTERY_WORK_STATUS_CODE.CHARGE },
  { name: '待机', value: BATTERY_WORK_STATUS_CODE.WAIT },
]

// 违约类型  1超时违约,2损坏
export const PENALTY_TYPE_CODE = {
  TIME_OUT: 1,
  DAMAGE: 2,
}

export const PENALTY_TYPE = [
  { name: '超时违约', value: PENALTY_TYPE_CODE.TIME_OUT },
  { name: '损坏', value: PENALTY_TYPE_CODE.DAMAGE }
]

// 1:使用中，2:确认中，3，已确认 4,已废弃
export const POINT_STATUS_CODE = {
  USE: 1,
  WAIT_CONFIRM: 2,
  CONFIRM: 3,
  ABANDON: 4,
}

export const POINT_STATUS = [
  { name: '使用中', value: POINT_STATUS_CODE.USE, style: "progress" },
  { name: '确认中', value: POINT_STATUS_CODE.WAIT_CONFIRM, style: 'success' },
  { name: '已确认', value: POINT_STATUS_CODE.CONFIRM, style: 'warning' },
  { name: '已废弃', value: POINT_STATUS_CODE.ABANDON, style: 'error' }
]

// 支付宝押金扣款状态
export const DEPOSIT_PAY_TYPE_CODE = {
  WAIT: 1,
  SUCCESS: 2,
  FAIL: 3
}

export const REFUND_TYPE_CODE = {
  DEPOSIT: 1,
  OTHEDER: 2,
}

export const REFUND_TYPE = [
  { name: "订单退款", value: REFUND_TYPE_CODE.OTHEDER },
  { name: "押金退款", value: REFUND_TYPE_CODE.DEPOSIT },
]

export const DEPOSIT_PAY_TYPE = [
  { name: '待支付', value: DEPOSIT_PAY_TYPE_CODE.WAIT, style: 'progress' },
  { name: '扣款成功', value: DEPOSIT_PAY_TYPE_CODE.SUCCESS, style: 'success' },
  { name: '失败', value: DEPOSIT_PAY_TYPE_CODE.WAFAILIT, style: 'default' },
]

//订单状态 -10(已取消),10(默认):未付款,20:已付款,30:已发货,40:已收货,50:已评价;60退货中 70退货退款完成
export const ORDER_STATUS_CODE = {
  CANCEL: -10,
  UNPAY: 10,
  PAYMENT: 20,
  RETURN_DEPOSIT: 70,
}

export const ORDER_STATUS = [
  { name: '已取消', value: ORDER_STATUS_CODE.CANCEL, style: "default" },
  { name: '未付款', value: ORDER_STATUS_CODE.UNPAY, style: "default" },
  { name: '已付款', value: ORDER_STATUS_CODE.PAYMENT, style: "success" },
  { name: '已退款', value: ORDER_STATUS_CODE.RETURN_DEPOSIT, style: "error" },
]

// 电池状态  1.运营中。2.需回收。3.维修中，4，仓库，-1：未知
export const BATTERY_STATUS_CODE = {
  OPERATION: 1,
  NEED_BACK: 2,
  MAINTENANCE: 3,
  STORE: 4,
  LOSE: -1,
}

export const BATTERY_STATUS = [
  { name: '运营中', value: BATTERY_STATUS_CODE.OPERATION, style: "success" },
  { name: '需回收', value: BATTERY_STATUS_CODE.NEED_BACK, style: "warnning" },
  { name: '维修中', value: BATTERY_STATUS_CODE.MAINTENANCE, style: 'progress' },
  { name: '仓库', value: BATTERY_STATUS_CODE.STORE, style: 'default' },
  { name: '未知', value: BATTERY_STATUS_CODE.LOSE, style: "error" }
]

// 租车状态
export const BICYCLE_ORDER_STATUS_CODE = {
  WIAT_PAY: 0,
  USE: 1,
  END: 2
}

export const BICYCLE_ORDER_STATUS = [
  { name: '待支付', value: BICYCLE_ORDER_STATUS_CODE.WIAT_PAY, style: "progress" },
  { name: '使用中', value: BICYCLE_ORDER_STATUS_CODE.USE, style: "success" },
  { name: '完成', value: BICYCLE_ORDER_STATUS_CODE.END, style: "default" },
]


// 套餐类型
export const PRODUCT_TYPE_CODE = {
  MONTH: 1,
  CONTINUOUS_MONTH: 2,
  ONCE: 3,
  ACTIVITY: 5,
}

export const PRODUCT_TYPE = [
  { name: '月卡', value: PRODUCT_TYPE_CODE.MONTH },
  { name: '连续包月', value: PRODUCT_TYPE_CODE.CONTINUOUS_MONTH },
  { name: '次卡', value: PRODUCT_TYPE_CODE.ONCE },
  { name: '活动商品', value: PRODUCT_TYPE_CODE.ACTIVITY },
]

// 支付方式 
export const PAYMENT_CODE = {
  WX_PAY: 1,
  ALI_PAY: 2,
  UNION_PAY: 3,
  CASH: 4
}

export const PAYMENT_TYPE = [
  { name: '微信', value: PAYMENT_CODE.WX_PAY },
  { name: '支付宝', value: PAYMENT_CODE.ALI_PAY },
  { name: '银联转账', value: PAYMENT_CODE.UNION_PAY },
  { name: '现金', value: PAYMENT_CODE.CASH },
]

// 提现方式
export const WITHDRAW_PAYMENT_CODE = {
  ALI_PAY: 1,
  WX_PAY: 2,
  UNION_PAY: 3,
  CASH: 4
}

export const WITHDRAW_PAYMENT_TYPE = [
  { name: '微信', value: WITHDRAW_PAYMENT_CODE.WX_PAY },
  { name: '支付宝', value: WITHDRAW_PAYMENT_CODE.ALI_PAY },
  { name: '银联转账', value: WITHDRAW_PAYMENT_CODE.UNION_PAY },
  { name: '现金', value: WITHDRAW_PAYMENT_CODE.CASH },
]


// 0待审核，1审核通过，2审核不通过，3已经转账
export const WITHDRAW_STATUS_CODE = {
  WAIT: 0,
  PASS: 1,
  REJECT: 2,
  DONE: 4,
  DONEING: 3,
}

// 用户资产变更明细 1推荐用户订阅奖励 2用户退订扣除 3提现, 5增加 6扣除

export const USER_ASSETS_TYPE_CODE = {
  YK_FIRST_REWARD: 1, // 用户订阅增加
  RETURN_DEDUCT: 2, // 用户退订扣除
  WITHDRAW: 3, // 提现扣除
  WITHDRAW_DEPOSIT_FAIL: 4, //提现失败返还
  SYS_ADD: 5, // 系统增加
  SYS_SUB: 6, // 系统扣除
  BYK_FIRST_REWARD: 7, // 推荐奖励
  BYK_RENEWAL_REWARD: 8, //半月卡续订奖励
  YK_RENEWAL_REWARD: 9, // 月卡续费奖励
  JK_FIRST_REWARD: 10, // 季卡首推奖励
  JK_RENEWAL_REWARD: 11, // 季卡续订奖励
  BNK_FIRST_REWARD: 12, // 半年卡首推奖励
  BNK_RENEWAL_REWARD: 13, // 半年卡续订奖励
  NK_FIRST_REWARD: 14, // 年卡首推奖励
  NK_RENEWAL_REWARD: 15, // 年卡续订奖励
}

export const USER_ASSETS_TYPE = [
  { name: "用户订阅月增加", value: USER_ASSETS_TYPE_CODE.YK_FIRST_REWARD, style: "success" },
  { name: "用户退订扣除", value: USER_ASSETS_TYPE_CODE.RETURN_DEDUCT, style: "error" },
  { name: "提现扣除", value: USER_ASSETS_TYPE_CODE.WITHDRAW, style: "error" },
  { name: "提现失败返还", value: USER_ASSETS_TYPE_CODE.WITHDRAW_DEPOSIT_FAIL, style: "success" },
  { name: "系统增加", value: USER_ASSETS_TYPE_CODE.SYS_ADD, style: "success" },
  { name: "系统扣除", value: USER_ASSETS_TYPE_CODE.SYS_SUB, style: "error" },
  { name: "推荐奖励", value: USER_ASSETS_TYPE_CODE.BYK_FIRST_REWARD, style: "success" },
]

// 用户金额变更明细类型  变更明细，1，用户充值，2，用户充电
export const USER_MONEY_DETAIL_TYPE_CODE = {
  RECHARGE: 1,
  RECHARGE_BATTERY: 2,
  SYS_ADD: 2,
}

export const USER_MONEY_DETAIL_TYPE = [
  { name: "用户充值", value: USER_MONEY_DETAIL_TYPE_CODE.RECHARGE },
  { name: "用户充电", value: USER_MONEY_DETAIL_TYPE_CODE.RECHARGE_BATTERY },
  { name: "系统补偿", value: USER_MONEY_DETAIL_TYPE_CODE.SYS_ADD },
]



// 用户提现状态 0待处理 1成功 2失败 3. 提现审核中。 4.提现审核失败
export const USER_WITHDRAW_CODE = {
  WAIT: 0,
  SUCCESS: 1,
  FAIL: 2,
  WIDTH_AUDIT: 3,
  AUDIT_FAIL: 4
}

export const USER_WITHDRAW = [
  { name: '待处理', value: USER_WITHDRAW_CODE.WAIT, style: "progress" },
  { name: '成功', value: USER_WITHDRAW_CODE.SUCCESS, style: "success" },
  { name: '失败', value: USER_WITHDRAW_CODE.FAIL, style: "error" },
  { name: '待审核', value: USER_WITHDRAW_CODE.WIDTH_AUDIT, style: "progress" },
  { name: '审核驳回', value: USER_WITHDRAW_CODE.AUDIT_FAIL, style: "error" },
]

// 是否允许提现
export const USER_ALLOW_WITHDRAW_CODE = {
  BAN: 2,
  ALLOW: 1
};

export const USER_ALLOW_WITHDRAW = [
  { name: '允许提现', value: USER_ALLOW_WITHDRAW_CODE.ALLOW, style: "success" },
  { name: '禁止提现', value: USER_ALLOW_WITHDRAW_CODE.BAN, style: "error" },
];



// 用户类型 1 骑手 2 普通用户
export const BATTERY_MAN_TYPE_CODE = {
  HORSEMAN: 1,
  NORMAL: 2,
}

export const BATTERY_MAN_TYPE =
  [{
    name: "骑手",
    value: BATTERY_MAN_TYPE_CODE.HORSEMAN,
  },
  {
    name: "普通用户",
    value: BATTERY_MAN_TYPE_CODE.NORMAL
  }]


export const IS_PUSHER_CODE = {
  NORMAL: 0,
  PUSHER: 1,
  STATION: 2
}

export const IS_PUSHER = [
  { name: '普通用户', value: IS_PUSHER_CODE.NORMAL },
  { name: '推广员', value: IS_PUSHER_CODE.PUSHER },
  { name: '站长', value: IS_PUSHER_CODE.STATION },
]

export const WITHDRAW_STATUS = [
  { name: '待审核', value: WITHDRAW_STATUS_CODE.WAIT, style: "default" },
  { name: '审核通过', value: WITHDRAW_STATUS_CODE.PASS, style: "success" },
  { name: '审核不通过', value: WITHDRAW_STATUS_CODE.REJECT, style: "error" },
  { name: '转账中', value: WITHDRAW_STATUS_CODE.DONEING, style: "progress" },
  { name: '提现成功', value: WITHDRAW_STATUS_CODE.DONE, style: "info" },
]

// 代理商充值 审批状态 1.待审核 2.审核通过 3.审核失败
export const AGENT_CHARGE_APPLY_CODE = {
  WAIT: 1,
  PASS: 2,
  REJECT: 3,
}

export const AGENT_CHARGE_APPLY = [
  { name: '待审核', value: AGENT_CHARGE_APPLY_CODE.WAIT, style: "default" },
  { name: '审核通过', value: AGENT_CHARGE_APPLY_CODE.PASS, style: "success" },
  { name: '审核不通过', value: AGENT_CHARGE_APPLY_CODE.REJECT, style: "error" },
]


// 1.充值 2.用户充值 3.退租结余 4逾期扣除
export const AGENT_DETAIL_TYPE_CODE = {
  CHARGE: 1,
  USER_CHARGE: 2,
  RETURN: 3,
  OVERDUE: 4
}

export const AGENT_DETAIL_TYPE = [
  { name: '充值', value: AGENT_DETAIL_TYPE_CODE.CHARGE, style: "success" },
  { name: '用户充值', value: AGENT_DETAIL_TYPE_CODE.USER_CHARGE, style: "error" },
  { name: '退租结余', value: AGENT_DETAIL_TYPE_CODE.RETURN, style: "success" },
  { name: '逾期扣除', value: AGENT_DETAIL_TYPE_CODE.OVERDUE, style: "error" },
]

// 代理商充值方式 1.线下转账 2.余额扣款
export const AGENT_CHARGE_WAY_CODE = {
  OFFLINE: 1,
  ONLINE: 2,
}

export const AGENT_CHARGE_WAY = [
  { name: "线下转账", value: AGENT_CHARGE_WAY_CODE.OFFLINE },
  { name: "余额扣款", value: AGENT_CHARGE_WAY_CODE.ONLINE },
]

// 是否实名认证
export const HAS_VERIFY_CODE = {
  VERIFIED: 1,
  UNVERIFIED: 0
}

export const HAS_VERIFY = [
  { name: '未认证', value: HAS_VERIFY_CODE.UNVERIFIED, style: "error" },
  { name: '已认证', value: HAS_VERIFY_CODE.VERIFIED, style: "success" },
]

export const IS_CONTINUE_CODE = {
  ABLE: 1,
  DISABLED: 0
}

// 是否开启自动订阅
export const IS_CONTINUE = [
  { name: '已开启', value: IS_CONTINUE_CODE.ABLE, style: 'success' },
  { name: '未开启', value: IS_CONTINUE_CODE.DISABLED, style: "error" },
]

// 订单类型 ORDER_TYPE 订单 1套餐 2押金 3.代收, 4. 用户充值

export const ORDER_TYPE_CODE = {
  PRODUCT_ORDER: 1,
  DEPOSITE: 2,
  AGENT_ORDER: 3,
  USER_RECHARGE: 4
}

export const ORDER_TYPE = [
  { name: '套餐', value: ORDER_TYPE_CODE.PRODUCT_ORDER },
  { name: '押金', value: ORDER_TYPE_CODE.DEPOSITE },
  { name: '代收', value: ORDER_TYPE_CODE.AGENT_ORDER },
  { name: '用户充值', value: ORDER_TYPE_CODE.USER_RECHARGE },
]

// 1.开启仓门；2充电中; 3充电异常,4,订单关闭，5订单完成
export const RECHARGE_ORDER_CODE = {
  OPEN_DOOR: "1",
  RECHARGEING: "2",
  ERROR: "3",
  COLSE: "4",
  COMPLETED: "5"
}

export const RECHARGE_ORDER = [
  { name: "开启仓门", value: RECHARGE_ORDER_CODE.OPEN_DOOR, style: 'progress' },
  { name: "充电中", value: RECHARGE_ORDER_CODE.RECHARGEING, style: 'success' },
  { name: "充电异常", value: RECHARGE_ORDER_CODE.ERROR, style: 'error' },
  { name: "订单关闭", value: RECHARGE_ORDER_CODE.COLSE, style: 'default' },
  { name: "订单完成", value: RECHARGE_ORDER_CODE.COMPLETED, style: 'default' },
]

// 充电结果 1.充电中,2：异常,3：成功
export const RECHARGE_ORDER_RESULT_CODE = {
  RECHARGEING: 1,
  ERROR: 2,
  COMPLETED: 3
}

export const RECHARGE_ORDER_RESULT = [
  { name: "充电中", value: RECHARGE_ORDER_CODE.RECHARGEING, style: 'progress' },
  { name: "充电异常", value: RECHARGE_ORDER_CODE.ERROR, style: 'error' },
  { name: "成功", value: RECHARGE_ORDER_CODE.COMPLETED, style: 'success' },
]


// 启用禁用
export const ABLED_CODE = {
  ABLE: 1,
  DISABLED: 0
}

// 是否开启自动订阅
export const ABLED = [
  { name: '已开启', value: ABLED_CODE.ABLE, style: 'success' },
  { name: '禁用', value: ABLED_CODE.DISABLED, style: "error" },
]

// 字段校验
export const validator = {
  qq: [
    {
      required: true,
      message: '请输入QQ'
    }
  ],
  weight: [
    {
      required: true,
      message: '请输入体重'
    }
  ],
  height: [
    {
      required: true,
      message: '请输入身高'
    }
  ],
  health: [
    {
      required: true,
      message: '请输入健康状况'
    }
  ],
  idcardBackPath: [
    {
      required: true,
      message: '请上传身份证反面'
    }
  ],
  idcardFacePath: [
    {
      required: true,
      message: '请上传身份证正面'
    }
  ],
  clothSize: [
    {
      required: true,
      message: '请选择服装尺码'
    }
  ],
  enSurname: [
    {
      required: true,
      message: '请输入姓（拼音）'
    }
  ],
  enName: [
    {
      required: true,
      message: '请输入名（拼音）'
    }
  ],
  shoeSize: [
    {
      required: true,
      message: '请选择鞋码'
    }
  ],
  photo: [
    {
      required: true,
      message: '请上传头像',
      trigger: 'change'
    }
  ],

  inaugurationUnitEmail: [
    {
      required: true,
      message: '请完善邮箱地址',
      trigger: 'blur'
    }
  ],
  contactAddress: [
    {
      required: true,
      message: '户籍地址不能为空！',
      trigger: 'blur'
    }
  ],
  unitId: [
    {
      required: true,
      message: '注册所属省市不能为空！',
      trigger: 'change'
    }
  ],
  cnName: [
    {
      required: true,
      message: '中文姓名不能为空！',
      trigger: 'blur'
    }
  ],

  idcard: [
    {
      required: true,
      message: '身份证不能为空！',
      trigger: 'blur'
    }
  ],
  area: [
    {
      required: true,
      message: '裁判归属地不能为空！',
      trigger: 'change'
    }
  ],
  sex: [
    {
      required: true,
      message: '性别不能为空！',
      trigger: 'change'
    }
  ],
  political: [
    {
      required: true,
      message: '政治面貌不能为空！',
      trigger: 'change'
    }
  ],
  prejudgeda: [
    {
      required: true,
      message: '发证时间不能为空',
      trigger: 'change'
    }
  ],
  certificateUnit: [
    {
      required: true,
      message: '发证单位不能为空',
      trigger: 'change'
    }
  ],
  certificateCode: [
    {
      required: true,
      message: '证书编号不能为空',
      trigger: 'change'
    }
  ],
  inaugurationUnitTel: [
    {
      required: true,
      message: '工作单位电话不能为空',
      trigger: 'blur'
    }
  ],
  ethnicity: [
    {
      required: true,
      message: '民族不能为空！',
      trigger: 'change'
    }
  ],
  mobile: [
    {
      required: true,
      message: '电话号码不能为空！',
      trigger: 'blur'
    }
  ],
  firstName: [
    {
      required: true,
      message: '姓不能为空！',
      trigger: 'blur'
    }
  ],
  lastName: [
    {
      required: true,
      message: '名不能为空！',
      trigger: 'blur'
    }
  ],
  birthday: [
    {
      required: true,
      message: '出生日期不能为空！',
      trigger: 'change'
    }
  ],
  inaugurationUnit: {
    required: true,
    message: '请输入工作单位',
    trigger: 'blur'
  },
  education: {
    required: true,
    message: '请选择学历',
    trigger: 'change'
  },
  level: {
    required: true,
    message: '请选择裁判等级',
    trigger: 'change'
  },
  levelPhotoPath: {
    required: true,
    message: '请上传证书扫描件',
    trigger: 'change'
  },
  speciality: {
    required: true,
    message: '请选择特长',
    trigger: 'change'
  },
  spec: {
    required: true,
    message: '请选择特长',
    trigger: 'change'
  },

};
