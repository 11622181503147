<script>
    export default {
        data() {
            return {
                isShow: false,
                defaultWidth: '650px',
                isClickModalClose: false,
            }
        },

        methods: {
            showModal() {
                this.isShow = true;
                if (this.$refs[this.formName]) { // 清除校验
                    this.$nextTick(()=>{
                        this.$refs[this.formName].clearValidate();
                    })
                }
            },
            
            closeModal() {
                this.isShow = false;
                setTimeout(() => {
                    if (this.$refs[this.formName]) {
                        this.$refs[this.formName].clearValidate();
                      //  this.$refs[this.formName].resetFields();
                    }
                }, 200)
            },

            validateForm() {
                return new Promise((resolve, reject) => {
                    this.$refs[this.formName].validate(res => {
                        if (res) {
                            resolve();
                        } else {
                            reject();
                        }
                    })
                })
            }
        }
    }
</script>
