<template>
    <div>
        <el-upload :before-upload="handleUpload" action="">
            <slot></slot>
        </el-upload>
        <UpLoading ref="upLoading"></UpLoading>
    </div>
</template>

<script>
    import UpLoading from './upLoading';

    export default {
        props: ['name'],
        components: {UpLoading},
        emits: ['submit'],
        data() {
            return {file: ''};
        },
        methods: {
            handleUpload(file) {
                this.file = file;
                return false
            },

            showLoading() {
                this.$refs.upLoading.showModal();
            },
            closeLoading() {
                this.$refs.upLoading.closeModal();
            }
        },
        watch: {
            file(newFile, oldFile) {
                if (newFile != '') {
                    let flag = false;
                    let arr = ["xls", "xlsx"];
                    //取出上传文件的扩展名
                    let index = newFile.name.lastIndexOf(".");
                    let ext = newFile.name.substr(index + 1);
                    for (let i = 0; i < arr.length; i++) {
                        if (ext == arr[i]) {
                            flag = true;
                        }
                    }
                    if (!flag) {
                        this.$message({
                            message: "格式不正确，请上传 xls 或 xlsx 格式的excel",
                            type: 'error'
                        })
                        return
                    }
                    let params = new FormData();
                    params.append("file", newFile);
                    this.$emit('submit', params);
                }
            }
        }
    }
</script>
