<template>
  <div>
    <router-view v-slot="{ Component }">
        <component :is="Component" v-if="!$route.meta.keepAlive"></component>

        <keep-alive>
          <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"></component>
        </keep-alive>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    keyPath() {
      return this.$route.fullPath;
    },
  },
};
</script>
