<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in menuList" :key="item.path">
        <span v-if="index == menuList.length-1" class="no-redirect">{{item.meta.title}}</span>
        <a v-else @click.prevent="gotoLink(item)">{{item.meta.title}}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      menuList: []
    }
  },
  created() {
    this.getBreadcrumb();
  },
  watch: {
    $route(route) {
      if (route.path.startsWith('/redirect/')) {
        return;
      }
      this.getBreadcrumb();
    }
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title);
      const first = matched[0];

      /*if (!this.isDashboard(first)) {
        const afterItem = { path: '/dashboard', meta: { title: '首页' } };
        matched = [afterItem].concat(matched);
      }*/

      this.menuList = matched.filter(item => item.meta && item.meta.title);
    },

    /*isDashboard(route) {
      const name = route && route.name;
      if (!name) { return; }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },*/

    gotoLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(path);
    }
  }
}
</script>

<style lang="less" scoped>
.no-redirect {
  color: #97a8be;
  cursor: text;
}
</style>
