<template>
  <v-chart :option="option" ref="charts" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import { ref, defineComponent, toRef } from "vue";

use([CanvasRenderer, PieChart, TooltipComponent]);

export default defineComponent({
  name: "gaugeCharts",
  props: ["target", "current"],
  components: {
    VChart,
  },

  setup(props, context) {
    let { target, current } = toRef(props);

    const option = ref({
      series: [
        {
          type: "pie",
          radius: ["130%", "150%"], // 放大图形
          center: ["50%", "80%"], // 往下移动  套住75%文字
          label: {
            show: false,
          },
          startAngle: 180,
          hoverOffset: 0, // 鼠标经过不变大
          data: [
            {
              value: current,
              itemStyle: {
                // 颜色渐变#00c9e0->#005fc1
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    { offset: 0, color: "#00c9e0" },
                    { offset: 1, color: "#005fc1" },
                  ],
                },
              },
            },
            { value: 0, itemStyle: { color: "#12274d" } }, // 颜色#12274d

            { value: target, itemStyle: { color: "transparent" } }, // 透明隐藏第三块区域
          ],
        },
      ],
    });

    const charts = ref(null);
    const updateCharts = (data) => {
      let newOption = option.value;
      let current = 0;
      let target = 0;
      let sub = 0;
      if (data.current >= data.target) {
        current = 100;
        sub = 0;
        target = 100;
      } else {
        current = data.current;
        sub = data.target - data.current;
        target = data.target;
      }
      newOption.series[0].data[0].value = current;
      newOption.series[0].data[1].value = sub;
      newOption.series[0].data[2].value = target;
      charts.value.setOption(newOption);
    };
    return { option, updateCharts, charts };
  },
});
</script>
