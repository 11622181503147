<template>
  <el-dialog
    title="修改密码"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="旧密码：" prop="passwordHash1">
        <el-input
          type="password"
          v-model="modalData.passwordHash1"
          placeholder="请输入密码"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="新密码：" prop="passwordHash">
        <el-input
          type="password"
          v-model="modalData.passwordHash"
          placeholder="请输入密码"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="确认密码：" prop="newpasswordConfirm">
        <el-input
          type="password"
          v-model="modalData.newpasswordConfirm"
          placeholder="请再次输入密码"
          :maxLength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        oldPassword: [
          {
            required: true,
            message: "旧密码不能为空",
          },
        ],
        passwordHash: [
          {
            required: true,
            message: "请输入新密码",
          },
          {
            pattern: /^.{6,18}$/,
            message: "密码长度限制6-18位",
          },
        ],
        newpasswordConfirm: [
          {
            validator: (rule, value, callback) => {
              if (value !== this.modalData.passwordHash) {
                callback("两次密码不一致");
              }
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
