import { per } from '@/const/permission';
export default function (IGNORE) {
    return {
        title: '代理商管理',
        name: 'agent',
        icon: 'icon-agent',
        children: [{
            title: '代理商列表',
            path: '/agent/list',
            name: 'agent-list',
            component: 'agent/list',
            permission: per.SHOW_AGENT_LIST,
            isMenu: true
        }, {
            title: '充值记录',
            path: '/agent/charge',
            name: 'agent-charge',
            component: 'agent/charge',
            permission: per.SHOW_AGENT_CHARGE,
            isMenu: true
        }, {
            title: '资产列表',
            path: '/agent/asset',
            name: 'agent-asset',
            component: 'agent/asset',
            permission: per.SHOW_AGENT_ASSETS,
            isMenu: true
        }, {
            title: '资产明细',
            path: '/agent/asset-detail/:id/:phone',
            name: 'agent-asset-detail',
            component: 'agent/asset-detail',
            permission: per.SHOW_AGENT_ASSETS,
            isMenu: false
        }, {
            title: '代理用户列表',
            path: '/agent/user',
            name: 'agent-user',
            component: 'agent/user',
            permission: per.SHOW_AGENT_USER,
            isMenu: true
        }, {
            title: '用户续费记录',
            path: '/agent/user-charge',
            name: 'agent-user-charge',
            component: 'agent/user-charge',
            permission: per.SHOW_AGENT_USER_CHARGE,
            isMenu: true
        }, {
            title: '代理账号管理',
            path: '/agent/account',
            name: 'agent-account',
            component: 'agent/account',
            permission: per.SHOW_AGENT_ACCOUNT,
            isMenu: true
        },
        ]
    }
}
